/* ======================================================================
 variable
====================================================================== */
// imgディレクトリまでのパス
$img_dir: '../img';

// コンテンツ幅
$content_width: 1280px;

// SP時の左右の余白（片方）
$sp_side_padding: 20px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 769px;

// $sp_bp以下がSP表示
$sp_bp: 768px;

//=================================
// font
//=================================
// google font
$noto: 'Noto Sans JP', sans-serif;
$roboto: 'Roboto Slab', serif;
$montserrat: 'Montserrat', serif;

//=================================
// color
//=================================

$color_1: #222;
$color_2: #AFAFAF;
$color_3: #DBDBDB;
$color_4: #E20028;
$color_5: #57DAEF;
$color_6: #75C757;
$color_7: #EA7A59;
$color_8: #79C3BF;
$color_9: #EA893A;
$color_10: #714EA2;
$color_11: #696969;
$color_12: #f73939;
$color_13: #ce0030;
$color_14: #ffa7cb;
$color_15: #de3691;
$color_16: #eeb94c;
$color_17: #6ebedd;
$color_18: #2874b4;
$color_19: #aad44c;
$color_20: #d1161b;
$color_21: #fff;
$color_22: #f0f0f0;
$color_23: #F5F5F5;
$color_24: #C3C3C3;
$color_25: #ccc;
$color_26: #D2D2D2;
$color_27: #DCF7FB;
$color_28: #F4F4F4;
$color_29: #666;
$color_30: #727272;
$color_31: #ff6e00;
$color_32: #c75600;
$color_33: #4cccc5;
$color_34: #6DBFDD;
$color_35: #C7E9F6;
$color_36: #FFF0D1;
$color_37: #81D8EC;
$color_38: #F6D3D9;
$color_39: #FBF1BB;
$color_40: #BAE0F3;
$color_41: #48B912;
$color_42: #21adc8;
$color_43: #2dd4e3;
$color_44: #FFF3F5;
$color_45: #01aaeb;
$color_46: #d7443c;
$color_47: #e03d95;
$color_48: #fda2c8;
$color_49: #EE6DAD;
$color_50: rgba(250,199,220,.5);
$color_51: #FFE8F2;
$color_52: #ED6EAD;
$color_53: #FFBD40;
$color_54: #FDEAC2;
$color_55: #DCFFD0;
$color_56: #ECA043;
$color_57: #FF8D2F;
$color_58: #333;
$color_59: #F53A3A;
$color_60: #1359D7;
$color_61: #2353c1;
$color_62: #24b4f4;
$color_63: #EE70AB;
$color_64: #EEA543;
$color_65: #57A5CC;
$color_66: #98CE50;
$color_67: #37A5E7;
$color_68: #000;
$color_69: #33efff;
$color_70: #24c0de;
$color_79: #F86671;
$color_80: #ebebeb;
$color_81: #888;
$color_82: #40f8ff;
$color_83: #fff0b1;
$color_84: #f5836e;
$color_85: #4DA2EB;
$color_86: #aaa;
$color_87: #57D5ED;
$color_88: #E0F6FC;
$color_89: #009AD3;
$color_90: #7EDBED;
$color_91: #FF8F86;
$color_92: #FFE0DB;
$color_93: #FA7068;
$color_94: #49C91C;
$color_95: #E5F5C1;
$color_96: #53CE28;
$color_97: #A6EA3C;
$color_98: rgba(144,195,237,.5);
$color_99: #C3E3FF;
$color_100: #4B99C8;
$color_101: #C6EDF6;
$color_102: rgba(232,138,110,.5);
$color_103: #FFE7E0;
$color_104: #888888;
$color_105: #ECA143;
$color_106: #CFEAF3;
$color_107: #1D251F;

$shadow_1: #0000001C;

$gradient_all: linear-gradient(to right, $color_2 0%, $color_11 100%);
$gradient_musician: linear-gradient(to right, $color_12 0%, $color_13 100%);
$gradient_performing: linear-gradient(to right, $color_14 0%, $color_15 100%);
$gradient_sound: linear-gradient(to right, $color_16 0%, $color_9 100%);
$gradient_movie: linear-gradient(to right, $color_17 0%, $color_18 100%);
$gradient_photo: linear-gradient(to right, $color_19 0%, $color_6 100%);

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
// In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);


//======================================================================
// mixin
//======================================================================

//=================================
// clearfix
//=================================
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//=================================
// safe-area
//=================================
@mixin safe_area {
  padding-top: constant(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

//=================================
// aタグのカラー変更
//=================================
@mixin a_tag($color1, $color2) {
  &:link,
  &:active,
  &:visited {
    color: $color1;
    cursor: pointer;
  }
  &:hover {
    color: $color2;
  }
}

//=================================
// プレースホルダーのカラー変更
//=================================
@mixin placeholder($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

//=================================
// media query
//=================================
@mixin mq($min_width, $max_width) {
  @media screen and (min-width: $min_width + px) and (max-width: $max_width + px) {
    @content;
  }
}
@mixin mq_min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq_max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc_bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp_bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp_bp + 1px) and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_tab{
  @media screen and (min-width: $sp_bp + 1px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_content {
  @media screen and (min-width: $pc_bp) and (max-width: $content_width) {
    @content;
  }
}

//=================================
// css hack
//=================================
// Chrome(and Safari, Opera)
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
// FireFox
@mixin firefox {
  _:lang(x)::-moz-placeholder, & {
    @content;
  }
}
// Edge
@mixin edge {
  _:lang(x)::-ms-, & {
    @content;
  }
}
// IE(11)
@mixin ie {
  _:-ms-lang(x)::-ms-backdrop, & {
    @content;
  }
}
// Safari
@mixin safari {
  _:lang(x)+_:-webkit-full-screen-document, & {
    @content;
  }
}

//=================================
// object fit images
//=================================
@mixin ofi($size) {
  object-fit: $size;
  @include ie {
    font-family: 'object-fit: #{$size};'
  }
}

//=================================
// hide scrollbar
//=================================
@mixin hide_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }
}

//=================================
// 三角形
//=================================
@mixin triangle($color) {
  content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17"><g transform="translate(15) rotate(90)" fill="none"><path d="M1.7,14L8.5,2l6.8,12H1.7 M0,15h17L8.5,0L0,15L0,15z" stroke="%23#{$color}"/></g></svg>');
  font-size: 0;
  line-height: 1;
}

//=================================
// アポストロフィ
//=================================
@mixin apostrophe($color) {
  content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.9 31.2"><path fill="%23#{$color}" d="M34,0c-8.5,4.3-12.8,11-12.7,19.7c0,7.2,2.9,11.5,8.2,11.5c3.9,0,6.8-2.8,6.8-7.1 c0-4.1-3.1-6.5-6.8-6.5c-0.3,0-0.6,0-0.9,0.1c0-5.5,2.8-9.1,8.3-12.2L34,0z M12.8,0C4.3,4.3,0,11,0,19.7c0,7.2,2.9,11.5,8.2,11.5 c3.9,0,6.8-2.8,6.8-7.1c0-4.1-3.1-6.5-6.8-6.5c-0.3,0-0.6,0-0.9,0.1c0-5.5,2.8-9.1,8.3-12.2L12.8,0z"/></svg>');
  font-size: 0;
  line-height: 1;
}

//=================================
// フォントサイズ
//=================================
@mixin fz($size) {
  font-size: $size + px;
  font-size: calc($size / 10) * 1rem;
}

//======================================================================
// keyframes
//======================================================================

//=================================
// アニメーション
//=================================
@keyframes bgscroll {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: -200vw 100%;
  }
}

@keyframes arrow {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@use 'setting' as *;

/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_content
================================= */

// pcから作成する場合
.l_content {
  padding: 0 80px;

  @include sp {
    width: auto;
    padding: 0 20px; // 15px / 320px * 100%
  }
  &_width {
    width: 1206px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 0;
    padding-right: 0;
    @include sp {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  &_mid_width {
    width: 1166px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 0;
    padding-right: 0;
    @include sp {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
    &.__pa_none {
      @include sp {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &_min_width {
    width: 966px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 0;
    padding-right: 0;
    @include sp {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
    &.__pa_none {
      @include sp {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &_tiny_width {
    width: 906px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 0;
    padding-right: 0;
    @include sp {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
    &.__pa_none {
      @include sp {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}


/* =================================
 l_container
================================= */
.l_container {
  position: relative;
  min-width: 1280px;

  @include sp {
    min-width: initial;
  }

}

/* =================================
 l_header
================================= */
.l_header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  min-width: 1280px;
  width: 100%;
  height: 112px;
  transition: .4s ease;

  @include sp{
    position: absolute;
    min-width: initial;
    height: 60px;
    top: -60px;
  }

  .is-magaopen &{
    background: #BCBCBC !important;
  }

  &_logo{
    position: absolute;
    left: 15px;
    top: 15px;
    @include mq_max(1300) {
      max-width: 400px;
    }

    @include sp{
      left: 15px;
      top: 20px;
      width: 272px;
      z-index: 2;
    }

    &_link{
      display: block;
    }

  }

  &_menu{
    position: absolute;
    right: 0;
    top: 0;

    @include sp{
      position: static;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      &_wrap{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(#{$img_dir}/common/sp/menu_bg.jpg) no-repeat center top;
        background-size: 100% auto;
        padding: 110px 20px 50px;
        overflow: auto;
        overscroll-behavior: contain;
        opacity: 0;
        visibility: hidden;
        transition: .4s ease;
      }

    }

    &_top{
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include sp{
        justify-content: space-between;
        flex-flow: wrap;
        margin: 15px 0 0 0;
      }

    }

    &_global{
      display: flex;
      justify-content: flex-end;

      @include sp{
        display: block;
      }

    }

  }

  &_btn{
    margin: 0 0 0 10px;

    @include sp{
      width: calc(50% - 5px);
      margin: 0;
    }

    &_link{
      display: block;
      padding: 0 40px;
      background: $color_1;
      border: 1px solid $color_1;
      line-height: 57px;
      text-align: center;
      @include a_tag(#fff,#fff);
      @include fz(14);
      transition: .4s ease;

      @include sp{
        position: relative;
        padding: 0 0 0 50px;
        border: none;
        border-radius: 4px;
        line-height: 70px;
        @include fz(18);
        font-weight: bold;
        text-align: left;

        &:before{
          content: "";
          position: absolute;
          left: 20px;
          top: 50%;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          border: 1px solid #fff;
          border-radius: 10px;
        }

        &:after{
          content: "";
          position: absolute;
          left: 27px;
          top: 50%;
          width: 8px;
          height: 6px;
          margin-top: -4px;
          @include triangle(fff);
        }

      }

      &_image{
        content: "";
        display: inline-block;
        margin: 0 10px 0 0;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: -5px;
        fill: #fff;
        transition: .4s ease;

        @include sp{
          display: none;
        }

      }

      &:hover{

        @include pc{

          background: #fff;
          color: $color_1;

          .l_header_btn_link_image{
            fill: $color_1;
          }

        }

      }

    }

    &-access{
      margin: 0 40px 0 0;
      font-weight: bold;

      .l_header_btn_link{
        position: relative;
        display: inline-block;
        padding: 0 0 5px 0;
        border: none;
        background: none;
        color: $color_1;
        line-height: 1;
        @include fz(13);
        transition: .4s ease;

        &:after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 2px solid $color_1;
          opacity: 0;
          transition: .4s ease;
        }

        &:hover:after{
          opacity: 1;
        }

      }

    }

    &-opencampus{

      @include sp{
        order: -1;
        width: 100%;
        margin-bottom: 10px;

        .l_header_btn_link{
          padding: 0;

          &:before,&:after{
            display: none;
          }

        }

      }

    }

    &-line .l_header_btn_link{
      padding: 0 43px;
    }

    @include sp{

      &-document .l_header_btn_link{
        background: linear-gradient(to right, #ff6e00 0%, #c75600 100%);
      }

      &-line .l_header_btn_link{
        padding: 0 0 0 50px;
        background: linear-gradient(to right, #25de25 0%, #039d03 100%);
      }

    }

  }

  &_link{
    padding: 20px;
    @include fz(13);
    line-height: 1;
    text-align: center;
    font-weight: bold;

    @include sp{
      display: none;
      padding: 0;
      text-align: left;
      @include fz(16);
      text-align: left;
    }

    &_link{
      position: relative;
      display: inline-block;
      padding: 0 0 5px 0;
      transition: .4s ease;

      &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 2px solid $color_1;
        opacity: 0;
        transition: .4s ease;
      }

      &:hover:after{
        opacity: 1;
      }

    }

    &-haschild{
      padding: 0;

      @include sp{
        display: block;
        border-bottom: 2px solid $color_1;

        &-last{
          border: none;
        }

      }

      .l_header_link_link{
        display: block;
        padding: 20px;

        &.is-show {
          background: $color_1;
          color: #fff;
        }

        @include sp{
          padding: 22px 10px;

          &:before{
            content: "";
            position: absolute;
            right: 10px;
            top: 50%;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            border-radius: 12px;
            background: $color_1;
          }

          &:after{
            content: "";
            position: absolute;
            left: auto;
            right: 18.5px;
            top: 50%;
            bottom: auto;
            @include triangle(fff);
            width: 8px;
            height: 10px;
            margin-top: -4.5px;
            border: none;
            opacity: 1;
            transform: rotate(90deg);
          }

          &.is-open:after{
            transform: rotate(90deg) scaleX(-1);
          }

        }

      }

      // &:hover{

      //   @include pc{

      //     .l_header_link_link{
      //       background: $color_1;
      //       color: #fff;
      //     }

      //     .l_header_mega{
      //       opacity: 1;
      //       visibility: visible;
      //     }

      //   }

      // }

    }

  }

  &_link_sp {
    .l_header_link_link {
      display: block;
    }
    @include sp {
      border-bottom: 2px solid #222;
      display: block;
      .l_header_link_link {
        padding: 22px 10px;
        position: relative;
        display: block;
        &:before {
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          width: 24px;
          height: 24px;
          margin-top: -12px;
          border-radius: 12px;
          background: #222;
        }
        &:after {
          content: "";
          position: absolute;
          left: auto;
          right: 18.5px;
          top: 50%;
          bottom: auto;
          @include triangle(fff);
          font-size: 0;
          line-height: 1;
          width: 8px;
          height: 10px;
          margin-top: -4.5px;
          border: none;
          opacity: 1;
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }

  &_mega{
    position: fixed;
    left: 0;
    top: 112px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 70px;
    background: #fff;
    transition: .4s ease;
    opacity: 0;
    visibility: hidden;
    &.is-show {
      @include pc {
        opacity: 1;
        visibility: visible;
      }
    }

    @include sp{
      opacity: 1;
      visibility: visible;
      position: static;
      display: none;
      margin: 0 0 18px 0;
      background: none;
      padding: 0;
      transition: none;
    }

    &.__course {
      display: block;
      @include sp {
        display: none;
      }
    }
    &__inr {
      display: flex;
      @include sp {
        display: block;
      }
    }
    &_course {
      width: 20%;
      @include sp {
        width: 100%;
      }
      .l_header_linksub {
        width: 100%;
      }
      &__child {
        @include sp {
          display: none;
        }
        .l_header_linksub {
          padding: 0 10px;
          @include sp {
            padding: 0;
          }
          & + & {
            margin: 10px 0 0;
          }
          &:last-of-type {
            @include sp {
              margin: 0 0 10px;
            }
          }
        }
      }
    }

  }

  &_linksub{
    width: 20%;
    padding: 15px 10px;
    text-align: left;
    &.__top {
      @include pc {
        width: 100%;
        text-align: center;
        .l_header_linksub_link {
          @include a_tag(#fff,#fff);
          background: $color_1;
          padding: 12px;
          &:before {
            background: #fff;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
          &:after {
            @include triangle(222);
            top: 17px;
            right: 16px;
          }
        }
      }
    }

    @include sp{
      width: auto;
      padding: 0;
    }

    &_link{
      display: block;
      padding: 0 0 12px 10px;
      border-bottom: 2px solid $color_1;
      font-weight: normal;
      line-height: 20px;
      transition: .4s ease;

      @include sp{
        margin: 0 0 2px 0;
        padding: 10px;
        background: #fff;
        border: none;
        line-height: 1;
      }

      @include pc{

        &:hover{
          opacity: .4;
        }

      }

      &:before{
        top: 0;
        width: 20px;
        height: 20px;

        @include sp{
          display: none;
        }

      }

      &:after{
        top: 6px;
        right: 5px;
        width: 8px;
        height: 6px;

        @include sp{
          right: 14px;
          top: 50%;
          width: 10px;
          height: 8px;
          margin-top: -4px;
          @include triangle(222);
        }

      }

      &.__course_sub {
        border: none;
        padding: 0 10px 20px;
        @include sp {
          padding: 10px 10px 10px 30px;
          background: none;
          margin: 10px 0 0;
        }
        &:before {
          background: none;
        }
        &:after {
          @include triangle(222);
        }
      }

      &.__dept {
        @include sp {
          padding: 10px;
          font-weight: bold;
        }
        &_p_art {
          @include sp {
            color: $color_15;
            &:after {
              @include triangle(de3691);
            }
          }
        }
        &_music {
          @include sp {
            color: $color_12;
            &:after {
              @include triangle(f73939);
            }
          }
        }
        &_sound {
          @include sp {
            color: $color_9;
            &:after {
              @include triangle(EA893A);
            }
          }
        }
        &_movie {
          @include sp {
            color: $color_18;
            &:after {
              @include triangle(2874b4);
            }
          }
        }
        &_photo {
          @include sp {
            color: $color_6;
            &:after {
              @include triangle(75C757);
            }
          }
        }
      }

      &.__music {
        color: $color_12;
        border-color: $color_12;
        @include sp {
          border-bottom: 2px solid $color_12;
        }
        &:before {
          background: $color_12;

          @include sp {
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
          }
        }
        &:after {
          @include triangle(fff);
          transform: scale(.9);
        }
      }
      &.__p_art {
        color: $color_15;
        border-color: $color_15;
        @include sp {
          border-bottom: 2px solid $color_15;
        }
        &:before {
          background: $color_15;

          @include sp {
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
          }
        }
        &:after {
          @include triangle(fff);
          transform: scale(.9);
        }
      }
      &.__sound {
        color: $color_9;
        border-color: $color_9;
        @include sp {
          border-bottom: 2px solid $color_9;
        }
        &:before {
          background: $color_9;
          @include sp {
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
          }
        }
        &:after {
          @include triangle(fff);
          transform: scale(.9);
        }
      }
      &.__movie {
        color: $color_18;
        border-color: $color_18;
        @include sp {
          border-bottom: 2px solid $color_18;
        }
        &:before {
          background: $color_18;
          @include sp {
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
          }
        }
        &:after {
          @include triangle(fff);
          transform: scale(.9);
        }
      }
      &.__photo {
        color: $color_6;
        border-color: $color_6;
        @include sp {
          border-bottom: 2px solid $color_6;
        }
        &:before {
          background: $color_6;
          @include sp {
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
          }
        }
        &:after {
          @include triangle(fff);
          transform: scale(.9);
        }
      }

    }

  }

  &_toggle{
    position: fixed;
    z-index: 2;
    right: 0;
    top: 0;
    display: none;
    background: $color_1;
    width: 60px;
    height: 60px;

    @include sp{
      display: block;
    }

    &_inner{
      position: absolute;
      right: 15px;
      height: 2px;
      background: #fff;

      &:nth-of-type(1){
        top: 17px;
        width: 30px;
  			animation: menu-bar01 .4s forwards;
      }
      &:nth-of-type(2){
        top: 29px;
        width: 24px;
        transition: .2s ease;
      }
      &:nth-of-type(3){
        top: 41px;
        width: 17px;
  			animation: menu-bar02 .4s forwards;
      }

    }

  }

  @include pc{

    .is-menufixed &{
      height: 105px;
      border-bottom: 2px solid #E5E5E5;
      background: #fff;
      transform: translateY(0);
      transition: .4s ease;

      .l_header_btn{

        &_link {
          position: relative;
          padding: 0 25px;
          line-height: 50px;
          border: none;
          background: none;

          &:before,
          &:after{
            content: "";
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: .4s ease;
          }
          &:after{
            opacity: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 1px solid transparent;
          }
          &:hover{

            &:before{
              opacity: 0;
            }

            &:after{
              opacity: 1;
            }

          }

        }

        &_inner{
          position: relative;
          z-index: 2;
        }

        &-access{
          margin: 0 25px 0 0;

          .l_header_btn_link{
            display: inline-block;
            padding: 0 0 5px 0;
            border: none;
            background: none;
            color: $color_1;
            line-height: 1.1;
            @include fz(13);

            &:after{
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              border-bottom: 2px solid $color_1;
              opacity: 0;
              transition: .4s ease;
            }

            &:hover:after{
              opacity: 1;
            }

          }

        }
        &-document .l_header_btn_link{

          &:before{
            background: linear-gradient(to right, #ff6e00 0%, #c75600 100%);
          }
          &:after{
            border-color: #FB6C00;
          }

          &:hover{
            color: #FB6C00;

            .l_header_btn_link_image{
              fill: #FB6C00;
            }

          }

        }
        &-opencampus .l_header_btn_link{

          &:before{
            background: linear-gradient(to right, #6dbfdd 0%, #1ba1ba 100%);
          }
          &:after{
            border-color: #6DBFDD;
          }

          &:hover{
            color: #6DBFDD;

            .l_header_btn_link_image{
              fill: #6DBFDD;
            }

          }

        }
        &-line .l_header_btn_link{
          padding: 0 28px;

          &:before{
            background: linear-gradient(to right, #25de25 0%, #039d03 100%);
          }
          &:after{
            border-color: #25DE25;
          }

          &:hover{
            color: #25DE25;

            .l_header_btn_link_image{
              fill: #25DE25;
            }

          }

        }

      }

      .l_header_mega{
        top: 103px;
      }

    }

  }

  &_sp{
    display: none;

    @include sp{
      display: block;
    }

    &_link{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 40px 0 0 0;
      @include fz(14);
      font-weight: bold;

      &_item{
        margin: 0 20px 20px 0;
      }

    }

    &_sns{
      display: flex;
      justify-content: space-between;
      margin: 10px 0 0 0;

      &_ttl{
        margin: 30px 0 0 0;
        font-family: $roboto;
        text-align: center;
        @include fz(14);
        line-height: 1;
        font-weight: bold;
      }

    }

    &_btn{
      width: 15%;
      margin: 0;
    }

  }

  &_mega_course__parent {
    @include sp {
      .l_header_linksub_link {
        &:after {
          transform: scale(0.9) rotate(90deg);
          right: 13px;
          margin-top: -3px;
        }
      }
      &.is-show {
        .l_header_linksub_link {
          &:after {
            transform: scale(0.9) rotate(-90deg);
            right: 15px;
          }
        }
      }
    }
  }

}

@include sp{

  .is-menuopen{

    .l_header_logo{
      position: fixed;
    }

    .l_header_menu_wrap{
      opacity: 1;
      visibility: visible;
    }

    .l_header_toggle_inner{

      &:nth-of-type(1){
  			animation: active-menu-bar01 .4s forwards;
      }
      &:nth-of-type(2){
  			opacity: 0;
        transition: .2s ease;
      }
      &:nth-of-type(3){
  			animation: active-menu-bar03 .4s forwards;
      }

    }

  }

}

@keyframes menu-bar01 {
	0% {
    width: 34px;
		transform: translateY(12px) rotate(45deg);
	}
	50% {
    width: 34px;
		transform: translateY(12px) rotate(0);
	}
	51% {
    width: 30px;
		transform: translateY(12px) rotate(0);
	}
	100% {
    width: 30px;
		transform: translateY(0) rotate(0);
	}
}
@keyframes menu-bar02 {
	0% {
    width: 34px;
		transform: translateY(-12px) rotate(-45deg);
	}
	50% {
    width: 34px;
		transform: translateY(-12px) rotate(0);
	}
	51% {
    width: 17px;
		transform: translateY(-12px) rotate(0);
	}
	100% {
    width: 17px;
		transform: translateY(0) rotate(0);
	}
}
@keyframes active-menu-bar01 {
	0% {
    width: 30px;
		transform: translateY(0) rotate(0);
	}
	50% {
    width: 30px;
		transform: translateY(12px) rotate(0);
	}
	51% {
    width: 34px;
		transform: translateY(12px) rotate(0);
	}
	100% {
    width: 34px;
		transform: translateY(12px) rotate(45deg);
	}
}
@keyframes active-menu-bar03 {
	0% {
    width: 17px;
		transform: translateY(0) rotate(0);
	}
	50% {
    width: 17px;
		transform: translateY(-12px) rotate(0);
	}
	51% {
    width: 34px;
		transform: translateY(-12px) rotate(0);
	}
	100% {
    width: 34px;
		transform: translateY(-12px) rotate(-45deg);
	}
}


/* =================================
 l_mainvisual
================================= */
.l_mainvisual {
  position: relative;
  height: calc(100vh - 120px);
  overflow: hidden;
  background: #f3f3f3;
  opacity: 0;
  transition: opacity .4s ease;

  @include sp{
    height: auto;
    margin: 60px 0 0 0;
  }

  &_img{
    position: relative;
    height: 100%;

    &_01{
      position: absolute;
      z-index: 1;
      right: 55px;
      top: 50px;
      width: 60%;
      height: calc(100% - 50px);
      opacity: 0;
      transform: translateX(-5%);
      clip-path: inset(0 100% 0 0);
      transition: 1s ease 2.2s;

      @include sp{
        width: 100%;
        height: 225px;
        top: 282px;
        right: auto;
      }

      &_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    &_02{
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 55%;
      background: url(#{$img_dir}/top/mv02.jpg) no-repeat left top;
      background-size: cover;
      box-shadow: 0px 13px 36px rgba(0, 0, 0, 0.12);
      opacity: 0;
      transform: translateX(-10%);
      clip-path: inset(0 0 0 100%);
      transition: clip-path .8s ease 2s, transform .8s ease 2s;

      @include sp{
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
        height: 225px;
        width: 100%;
        background-image: url(#{$img_dir}/top/sp/mv02.jpg);
        box-shadow: none;
      }

      &_item{
        width: 100%;
        height: 100%;

        &_img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          clip-path: inset(0 0 0 100%);
          transition: .8s ease;
        }

        &.show{
          opacity: 1;

          .l_mainvisual_img_02_item_img{
            clip-path: inset(0 0 0 0);
          }

        }
        &.hide{
          opacity: 0;
        }

      }

    }

  }

  &_txt{
    position: absolute;
    z-index: 3;
    right: 110px;
    top: 120px;
    line-height: 1;
    @include fz(18);
    font-weight: bold;
    opacity: 0;
    transform: translateX(-30%);
    transition: 1s ease 2.6s;

    @include sp{
      right: 15px;
      bottom: 210px;
      top: auto;
      @include fz(12);
    }

  }

  &_copy{
    position: absolute;
    z-index: 5;
    left: -1.2%;
    bottom: 75px;
    width: 102%;
    padding: 15.9% 0 0 0;
    overflow: hidden;

    @include sp{
      top: 222px;
      left: -2%;
      bottom: auto;
      width: 104%;
    }

    &_mono{
      position: absolute;
      z-index: 6;
      left: -1.2%;
      bottom: 75px;
      width: 102%;
      padding: 15.9% 0 0 0;
      overflow: hidden;
      transition: 1s ease;

      @include sp{
        top: 222px;
        left: -2%;
        bottom: auto;
        width: 104%;
      }

      &_inner{
        position: absolute;
        top: 0;
        width: auto;
        height: 100%;
        opacity: 0;
        transition: .7s ease,;

        &-01{
          left: 0;
          transform: translateX(50px);
          transition-delay: 0s;
        }
        &-02{
          left: 5.896%;
          transform: translateX(80px);
          transition-delay: 0.1s;
        }
        &-03{
          left: 23.985%;
          transform: translateX(120px);
          transition-delay: 0.2s;
        }
        &-04{
          left: 35.738%;
          transform: translateX(150px);
          transition-delay: 0.3s;
        }
        &-05{
          left: 43.748%;
          transform: translateX(180px);
          transition-delay: 0.4s;
        }
        &-06{
          left: 57.185%;
          transform: translateX(210px);
          transition-delay: 0.5s;
        }
        &-07{
          left: 63.877%;
          transform: translateX(240px);
          transition-delay: 0.6s;
        }
        &-08{
          left: 80.275%;
          transform: translateX(270px);
          transition-delay: 0.7s;
        }
        &-09{
          left: 94.861%;
          transform: translateX(300px);
          transition-delay: 0.8s;
        }

      }

      &.is-masked{
        opacity: 0;
      }

    }

    &.is-masked{
      mask-image: url(../img/top/mv_txt.svg);
      mask-repeat: no-repeat;
      mask-position: 0 0;
      mask-size: auto 100%;

      background-image: linear-gradient(
        90deg,
        #222 0%,
        #1a2c45 38.98%,
        #3d4a5d 41.01%,
        #677582 42.77%,
        #b2c3cc 44.51%,
        #dfeaee 46.04%,
        #fbfaf8 47.48%,
        #fce2d4 49.14%,
        #e5b299 51.53%,
        #a96f57 54.28%,
        #8b6656 56.29%,
        #1b3847 58.88%,
        #222 100%
      );
      background-size: 200% 100%;
      background-repeat: no-repeat;
      background-color: #222;

      animation-name: txtshine;
      animation-duration: 3.5s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

    }

  }

  &_bg{
    position: absolute;
    z-index: 4;
    left: -3%;
    bottom: 90px;
    width: 106%;
    overflow: hidden;

    @include sp{
      left: -10%;
      top: 200px;
      bottom: auto;
      width: 120%;
    }

  }

  &_scroll{
    position: absolute;
    z-index: 6;
    right: 55px;
    bottom: 0;
    width: calc(100vh - 185px);
    transform: rotate(90deg);
    transform-origin: right bottom;
    line-height: 55px;
    @include fz(12);
    opacity: 0;
    transition: 1s ease 4.5s;

    @include sp{
      left: -100vw;
      right: auto;
      bottom: 0;
      width: 100%;
      padding: 0 45px 0 0;
      @include fz(10);
      line-height: 25px;
      transform-origin: right bottom;
      text-align: right;
    }

    &:after{
      content: "";
      display: inline-block;
      width: 26px;
      height: 6px;
      margin: 0 0 0 20px;
      background: url(#{$img_dir}/top/arrow.svg) no-repeat;
      background-size: contain;
      vertical-align: 3px;
      animation: arrow 2s ease infinite;
    }

  }

  &_info{
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    display: flex;
    width: 770px;
    height: 68px;
    padding: 0 0 0 30px;
    border-radius: 10px 0 0 0;
    background: #fff;
    line-height: 68px;
    transform: translateY(100%);
    transition: .6s ease 4s;

    @include sp{
      position: relative;
      display: block;
      width: auto;
      height: 58px;
      margin: 467px 0 0 30px;
      padding: 10px 70px 10px 10px;
      border-bottom: 2px solid #F4F4F4;
      line-height: 1;
    }

    &_ttl{
      margin: 0 35px 0 0;
      @include fz(14);
      font-family: $roboto;
      white-space: nowrap;

      @include sp{
        margin: 0 12px 0 0;
        @include fz(12);
      }

    }

    &_date{
      margin: 0 20px 0 0;
      color: $color_2;
      font-family: $roboto;
      @include fz(12);
      white-space: nowrap;

      @include sp{
        font-weight: normal;
      }

    }

    &_lead{
      max-width: 540px;
      @include fz(14);
      font-weight: bold;
      flex: 1 1;
      @include sp{
        display: block;
        margin: 10px 0 0 0;
        @include fz(11);
        font-weight: normal;
      }

      &_link{
        position: relative;
        display: block;
        padding-right: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include sp{
          position: static;
          padding: 0;
        }

        &_inner{
          transition: .4s ease;
        }

        &:before{
          content: "";
          position: absolute;
          right: 0;
          width: 68px;
          height: 68px;
          background: $color_1;

          @include sp{
            top: 0;
            width: 58px;
            height: 58px;
          }

        }

        &:after{
          position: absolute;
          right: 25px;
          bottom: 25px;
          width: 15px;
          height: 17px;
          @include triangle(fff);
          line-height: 1;
          transition: .4s ease;

          @include sp{
            right: 21px;
            top: 21px;
            bottom: auto;
            width: 16px;
            height: 13px;
          }

        }

        @include pc{

          &:hover{

            .l_mainvisual_info_lead_link_inner{
              opacity: .4;
            }

            &:after{
              transform: translateX(5px);
            }

          }

        }

      }

    }

  }

  .is-loaded &{
    opacity: 1;
    background: #fff;

    .l_mainvisual_img_01,
    .l_mainvisual_img_02,
    .l_mainvisual_txt,
    .l_mainvisual_copy_mono_inner{
      opacity: 1;
      transform: translateX(0);
    }
    .l_mainvisual_img_01,
    .l_mainvisual_img_02{
      clip-path: inset(0 0 0 0);
      transform: translateX(0);
    }

    .l_mainvisual_info{
      opacity: 1;
      transform: translateY(0);
    }

    .l_mainvisual_scroll{
      opacity: 1;
    }

  }

}

@keyframes txtshine {
  0% {
    background-position: -600% 0;
  }
  25% {
    background-position: 0% 0;
  }
  100% {
    background-position: 600% 0;
  }
}

/* =================================
 l_overlay
================================= */
.l_overlay {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color_1,.3);
  opacity: 0;
  visibility: hidden;
  transition: .4s ease;

  .is-magaopen &{
    opacity: 1;
    visibility: visible;
  }

}

/* =================================
 l_about
================================= */
.l_about {
  background: url(#{$img_dir}/top/about_bg.svg) no-repeat center top #F5F5F5;
  background-size: 110% auto;

  @include sp{
    padding-left: 0;
    padding-right: 0;
    background-position: 50% 40px;
    background-size: 690px auto;
  }

  &_inner{
    max-width: 1206px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 80px;

    @include sp{
      padding: 60px 0;
    }

  }

  .c_ttl{
    text-align: center;

    .c_ttl_main:before{
      display: none;
    }

  }

  &_menu{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0 0 0;

    @include sp{
      display: block;
      margin: 15px 0 0 0;
    }

  }

  &-sub{
    background: none;

    .c_ttl{
      text-align: left;

      @include sp{
        margin-left: 20px;
        margin-right: 20px;
      }

    }

    .l_about_menu{
      margin-top: 20px;
    }

  }

  &_item{
    width: calc(50% - 25px);
    border-top: 2px solid rgba($color_3,.4);

    @include sp{
      width: auto;
      border-top-width: 1px;
    }

    &:nth-last-child(-n+2){
      border-bottom: 2px solid rgba($color_3,.4);

      @include sp{
        border-bottom-width: 1px;
      }

    }

  }

  &_link{
    display: flex;
    padding: 20px 50px 20px 0;

    @include sp{
      padding: 20px 40px 20px 10px;
    }

    &:before{
      top: calc(50% - 15px);

      @include sp{
        top: calc(50% - 10px);
        right: 10px;
      }

    }
    &:after{
      top: calc(50% - 6px);

      @include sp{
        top: calc(50% - 4px);
        right: 16px;
      }

    }

  }

  &_img{
    flex-grow: 0;
    min-width: 220px;
    max-width: 220px;
    margin: 0 20px 0 0;
    overflow: hidden;
    border-radius: 4px;

    @include sp{
      min-width: 115px;
      max-width: 115px;
      margin: 0 10px 0 0;
    }

    &_image{
      transition: .4s ease;
    }

  }

  &_link:hover{

    .l_about_img_image{
      transform: scale(1.1);
    }

  }

  &_txt{
    padding: 15px 0 0 0;

    @include sp{
      padding: 0;
    }

  }

  &_ttl{
    display: inline-block;
    border-bottom: 2px solid $color_1;
    @include fz(20);
    font-weight: bold;

    @include sp{
      padding: 0 0 5px 0;
      @include fz(16);
    }

  }

  &_desc{
    margin: 15px 0 0 0;
    @include fz(14);
    font-weight: normal;
    line-height: 1.7;

    @include sp{
      margin: 10px 0 0 0;
    }

  }

}

/* =================================
 l_opencampus
================================= */
.l_opencampus {
  position: relative;
  padding: 40px 0;
  background: linear-gradient(to right, #8DDBF7 0%, #1BACC7 100%);

  @include sp{
    padding: 60px 0 50px;
  }

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 90px;
    z-index: 3;
    width: 100%;
    height: 86px;
    background: url(#{$img_dir}/common/opencampus_txt.svg) repeat-x -40% 0;
    animation: bgscroll 50s linear infinite;

    @include sp{
      top: 130px;
      height: 12vw;
      background-size: auto 12vw;
    }

  }

  .c_ttl{
    position: relative;
    z-index: 4;
    max-width: 1206px;
    margin: 0 auto;

    @include sp{
      padding: 0 20px;
    }

    &_main:before{
      display: none;
    }

  }

  &_slider{
    position: relative;
    z-index: 2;
    margin: 15px 0 0 0;
    overflow: hidden;

    @include sp{
      margin: 30px 0 0 0;
    }

  }

  &_photo{
    margin: 0 7.5px;
    width: 330px;

    @include sp{
      margin: 0 5px;
    }

  }

  &_menu{
    position: relative;
    z-index: 4;
    display: flex;
    max-width: 1104px;
    margin: -50px auto 0;
    padding: 40px 0;
    background: #fff;
    border-radius: 4px;

    @include sp{
      display: block;
      margin: -65px 30px 0;
      padding: 0 30px;
    }

  }

  &_item{
    width: 33.33%;
    padding: 30px 0;
    border-right: 2px solid rgba($color_3,.8);
    text-align: center;

    @include sp{
      width: auto;
      border-right: none;
      border-bottom: 1px solid rgba($color_3,.8);
    }

    &:last-of-type{
      border: none;
    }

  }

  &_ttl{
    display: block;
    @include fz(20);
    font-weight: bold;

    @include sp{
      @include fz(20);
    }

  }

  &_txt {
    @include fz(13);
    color: $color_30;
    margin: 10px 0 0;
    @include sp {
      @include fz(14);
    }
  }

  &_lead{
    margin: 15px 0 20px;
    @include fz(14);
    color: $color_3;

    @include sp{
      margin: 10px 0 15px;
    }

  }

  &_btn{
    margin-top: 20px;
    padding-top: 19px;
    padding-bottom: 19px;

    &:before{
      padding: 19px 0;
    }

    @include sp{
      margin-top: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

  }

  &__btn {
    margin: 40px 0 0;
    text-align: center;
    @include sp {
      margin: 30px 0 0;
    }
    .l_opencampus_btn {
      width: 307px;
      margin: 0 auto;
      @include sp {
        width: 255px;
        padding: 17px 0;
      }
    }
  }

}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
}

/* =================================
 l_footer
================================= */
.l_footer {

  a{
    transition: .4s ease;

    @include pc{

      &:hover{
        opacity: .4;
      }

    }

  }

  &_bg{
    background: $color_1;
  }

  &_inner{
    max-width: 1104px;
    margin: 0 auto;
    padding: 60px 0;

    @include sp{
      padding: 40px 0 55px;
    }

  }

  &_top{
    display: flex;
    justify-content: center;

    @include sp{
      display: block;
    }

    &_btn{
      position: relative;
      display: block;
      width: 380px;
      margin: 0 15px;
      padding: 25px 120px 25px 20px;
      border-radius: 4px;
      @include a_tag(#fff,#fff);

      @include sp{
        width: auto;
        padding-right: 60px;

        &+&{
          margin-top: 30px;
        }

      }

      &:after{
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-position: 0 0;
      }

      &-document{
        background: linear-gradient(to right, #ff6e00 0%, #c75600 100%);

        &:after{
          right: 18px;
          bottom: 0;
          width: 97px;
          height: 153px;
          background-image: url(#{$img_dir}/common/footer_document.png);

          @include sp{
            right: 10px;
            width: 83px;
            height: 130px;
            background-size: contain;
          }

        }

      }

      &-line{
        background: linear-gradient(to right, #25de25 0%, #039d03 100%);

        &:after{
          right: 15px;
          bottom: 6px;
          width: 93px;
          height: 149px;
          background-image: url(#{$img_dir}/common/footer_line.png);

          @include sp{
            right: 10px;
            width: 83px;
            height: 130px;
            background-size: contain;
          }

        }

      }

      &_ttl{
        position: relative;
        @include fz(18);
        font-weight: bold;
        line-height: 20px;

        &:before{
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0 8px 0 0;
          border-radius: 10px;
          border: 1px solid #fff;
          vertical-align: -3px;
        }

        &:after{
          @include triangle(fff);
          position: absolute;
          left: 7px;
          top: 9.5px;
          width: 8px;
          height: 8px;
        }

      }

      &_desc{
        margin: 10px 0 0 30px;
        @include fz(13);
        line-height: 1.6;
      }

    }

  }

  &_nav{
    display: flex;
    justify-content: space-between;
    margin: 50px 0 0 0;

    @include sp{
      display: block;
      margin: 40px 20px 0;
    }

  }

  &_menus{

    @include sp{

      &:first-of-type{
        border-top: 1px solid #5A5A5A;
      }

    }

    a {
      @include pc {
        @include a_tag(#fff,#fff);
      }
    }

  }

  &_menu{

    @include sp{
      border-bottom: 1px solid #5A5A5A;

      &_top{
        position: relative;
        display: block;
        padding: 30px 10px;
        @include a_tag(#fff,#fff);

        &:before{
          content: "";
          position: absolute;
          right: 10px;
          top: calc(50% - 12px);
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background: #fff;
        }

        &:after{
          content: "";
          position: absolute;
          right: 17px;
          top: calc(50% - 2px);
          @include triangle(222);
          width: 8px;
          height: 7px;
          transform: rotate(90deg);
        }

        &.is-open:after{
          transform: rotate(90deg) scaleX(-1);
        }

      }

    }

    &+&{
      margin: 30px 0 0 0;

      @include sp{
        margin: 0;
      }

    }

    &_top{
      @include fz(16);
      font-weight: bold;
    }

    &_list{
      margin: 20px 0 0 0;
      padding: 0 0 0 25px;
      border-left: 2px solid rgba($color_3,.3);

      @include sp{
        display: none;
        margin: 0 0 30px 0;
        border: none;
        padding: 0;
      }

    }

    &_item{
      @include fz(14);
      font-weight: normal;

      @include sp{
        line-height: 1;
        @include fz(13);

        &_link{
          position: relative;
          display: block;
          padding: 10px;
          background: #fff;
          @include a_tag($color_107, $color_107);

          &:after{
            content: "";
            position: absolute;
            right: 14px;
            top: 50%;
            width: 10px;
            height: 8px;
            margin-top: -4px;
            @include triangle(222);
          }

        }

      }

      &+&{
        margin: 15px 0 0 0;

        @include sp{
          margin: 2px 0 0 0;
        }

      }

      .l_footer_menu_list_sub_ttl {
        display: block;
        position: relative;
        padding-right: 74px;
        @include a_tag(#fff,#fff);
        @include sp {
          @include a_tag($color_107,$color_107);
          position: relative;
          display: block;
          padding: 10px;
          background: #fff;
          border-bottom: 2px solid;
          &:before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            right: 7px;
            top: 6px;
            position: absolute;
            border-radius: 12px;
          }
          &:after {
            position: absolute;
            right: 13px;
            top: 50%;
            width: 10px;
            height: 8px;
            margin-top: -2px;
            font-size: 0;
            line-height: 1;
            transform: scale(.9) rotate(90deg);
            @include triangle(fff);
            transition: .4s ease;
          }

          &.is-show {
            &:after {
              margin-top: -3px;
              right: 15px;
              transform: rotate(-90deg);
            }
          }
        }
        @include pc {
          &:before,
          &:after {
            position: absolute;
          }
          &:before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background: #fff;
            right: 0;
          }
          &:after {
            @include triangle(222);
            right: 5px;
            top: 6px;
            width: 8px;
            height: 7px;
            transform: rotate(90deg);
            transition: .3s;
          }
          &.is-show {
            &:after {
              right: 7px;
              transform: rotate(-90deg);
            }
          }
        }

        &.__music {
          @include sp {
            @include a_tag($color_12,$color_12);
            border-image: linear-gradient(to right, #F73939 0%, #CE0030 100%);
            border-image-slice: 1;
            &:before {
              background: $color_12;
            }
          }
        }
        &.__p_art {
          @include sp {
            @include a_tag($color_15,$color_15);
            border-image: linear-gradient(to right, #FFA7CB 0%, #DE3691 100%);
            border-image-slice: 1;
            &:before {
              background: $color_15;
            }
          }
        }
        &.__sound {
          @include sp {
            @include a_tag($color_9,$color_9);
            border-image: linear-gradient(to right, #EEB94C 0%, #EA893A 100%);
            border-image-slice: 1;
            &:before {
              background: $color_9;
            }
          }
        }
        &.__movie {
          @include sp {
            @include a_tag($color_18,$color_18);
            border-image: linear-gradient(to right, #6EBEDD 0%, #2874B4 100%);
            border-image-slice: 1;
            &:before {
              background: $color_18;
            }
          }
        }
        &.__photo {
          @include sp {
            @include a_tag($color_6,$color_6);
            border-image: linear-gradient(to right, #AAD44C 0%, #75C757 100%);
            border-image-slice: 1;
            &:before {
              background: $color_6;
            }
          }
        }

      }

      .l_footer_menu_list_sub{
        margin: 0 0 30px 0;
        display: none;
        @include sp{
          margin: 2px 0 0 0;
        }

        &_item{
          margin: 15px 0 0 0;

          @include sp{
            margin: 0;

            &+&{
              margin: 2px 0 0 0;
            }

          }

          &:before{
            content: "";
            display: inline-block;
            width: 10px;
            margin: 0 10px 0 0;
            border-top: 2px solid #fff;
            vertical-align: middle;

            @include sp{
              display: none;
            }

          }

          a {
            @include sp {
              @include a_tag(#fff,#fff);
              padding: 10px 10px 10px 30px;
              background: none;
              margin: 10px 0 0;
              display: block;
              position: relative;
              &:after {
                @include triangle(fff);
                right: 14px;
                top: 50%;
                width: 10px;
                height: 8px;
                margin-top: -4px;
                position: absolute;
              }
            }
          }

          &.__dept {
            a {
              @include sp {
                font-weight: bold;
                padding: 10px;
              }
            }
          }

          &:last-of-type {
            @include sp {
              margin: 0 0 10px;
            }
          }
          

        }

      }

    }

  }

  &_banner{
    display: flex;
    justify-content: space-between;
    margin: 60px 0 0 0;

    @include sp{
      justify-content: center;
      flex-wrap: wrap;
      margin: 40px 17.5px 0;
    }

    &_item{
      width: 200px;

      @include sp{
        width: calc(50% - 5px);
        margin: 0 2.5px 5px;
      }

    }

  }

  &_btm{
    display: flex;
    justify-content: space-between;
    max-width: 1104px;
    margin: 0 auto;
    padding: 30px 0 50px;

    @include sp{
      flex-direction: column-reverse;
      padding: 40px 20px 50px;
    }

  }

  &_txt{
    padding: 10px 0 0 0;

    @include sp{
      margin: 45px 0 0 0;
      padding: 0;
    }

  }
  &_logo_image{
    width: 265px;

    @include sp{
      width: 100%;
    }

  }
  &_add{
    margin: 25px 0 0 0;
    @include fz(12);
    line-height: 1.7;
    color: rgba(#0D0D0D,.5);
    font-style: normal;

    @include sp{
      margin: 20px 0 0 0;
      @include fz(14);
    }

  }
  &_copy{
    margin: 25px 0 0 0;
    @include fz(12);

    @include sp{
      margin: 30px 0 0 0;
    }

  }

  &_link{

    &_sub{
      display: flex;
      justify-content: flex-end;
      @include fz(14);

      @include sp{
        display: none;
      }

    }

    &_item{
      margin: 0 0 0 1em;
    }

    &_sns{
      display: flex;
      justify-content: flex-end;
      margin: 30px 0 0 0;

      @include sp{
        justify-content: space-between;
        margin: 0;
      }

      &_ttl{
        display: none;

        @include sp{
          display: block;
          margin: 0 0 20px 0;
          font-family: $roboto;
          text-align: center;
          @include fz(14);
          line-height: 1;
        }

      }

    }

    &_btn{
      width: 30px;
      margin: 0 0 0 20px;

      @include sp{
        width: 14%;
        margin: 0;
      }

    }

  }
}

/* =================================
 l_page
================================= */
.l_page {

  &_header{

    @include sp{
      height: auto;
      margin: 60px 0 0 0;
      padding-top: 40px;

      .c_ttl-m .c_ttl_main{
        @include fz(36);

        &.u_en{
          @include fz(44);
        }

      }

    }

  }

  &_kv{
  }

  &_container{
    position: relative;
    margin: 10px 0 0 80px;
    background: #fff;

    @include sp{
      margin: 7px 0 0 0;
    }

    &-withoutkv{
      padding-top: 60px;
    }

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 5px;
    }

  }

  &_wrapper{
    padding: 0 0 100px 0;

    @include sp{
      padding: 0 0 50px 0;
    }

  }

  &_single{
    padding: 0 160px 100px;

    @include sp{
      padding: 0 20px 30px;
    }

  }

  &_main{
    position: relative;
    padding: 0 80px 0 300px;

    @include sp{
      display: block;
      padding: 0 20px;
    }

  }

  &_nav{
    float: left;
    position: sticky;
    z-index: 3;
    top: 115px;
    width: 310px;
    padding: 40px;
    margin: 0 0 0 -80px;
    border-radius: 0 4px 4px 0;

    @include sp{
      display: none;
    }

    &_item{
      @include fz(14);
      font-weight: bold;

      &+&{
        margin: 16px 0 0 0;

      }

    }

    &_link{
      display: block;
      @include a_tag(#fff,#fff);

      &:before{
        top: 4px;
      }

      &:after{
        top: 10px;
      }

    }

  }

  &_sec{
    margin: 80px auto 0;

    @include sp{
      margin-top: 60px;

      .c_ttl-l .c_ttl_main{
        @include fz(28);

        &:before{

          @include sp{
            top: 20px;
            left: -12px;
          }

        }

      }

    }

    &:not(.l_page_sec-common) p{
      line-height: 1.75;

      @include sp{
        @include fz(14);
      }

    }

    &_head{

      @include sp{
        display: flex;
        flex-direction: column-reverse;
      }

    }

  }

  &_sep{
    position: relative;
    height: 122px;
    margin: 80px -80px 0 -300px;
    background-repeat: repeat-x;
    animation: bgscroll 50s linear infinite;

    @include sp{
      height: 108px;
      margin: 60px -20px 0 -20px;
      background-size: auto 100%;
    }

  }

}

.l_page_container-wide{
  margin-left: 0;
  margin-bottom: 100px;

  @include sp{
    margin-bottom: 60px;
  }

  &:before{
    display: none;
  }

  .l_page_wrapper{
    display: flex;
    flex-direction: row-reverse;
    padding: 70px 80px 100px;

    @include sp{
      display: block;
      padding: 10px 0 60px;
    }

  }

}

//コース
.p_course-sub{

  .l_page_header{

    .c_ttl{
      display: inline-block;
      vertical-align: bottom;
      margin-right: 25px;

      @include sp{
        display: block;
      }

    }

    .c_course_tag{
      display: inline-block;
      padding-bottom: 15px;
      vertical-align: bottom;

      @include sp{
        display: block;
        padding-bottom: 0;
      }

      &_item{
        padding-top: 6px;
        padding-bottom: 6px;
        margin-right: 10px;

        @include sp{
          @include fz(10);
        }

      }

    }

  }

  .l_page_container:before{
    display: none;
  }

}

/* =================================
 l_strength
================================= */
.l_strength{
  counter-reset: strength_sec 0;

  &_nav{
    margin: 20px -80px 0 -300px;
    padding: 40px 80px 40px 300px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    counter-reset: strength_nav 0;

    @include sp{
      margin: 15px -20px 0;
      padding: 15px 10px 15px 15px;
      background-image: url(#{$img_dir}/common/sp/strength_nav.jpg);
    }

  }

  &_list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &_item{
    position: relative;
    width: 23.33%;
    margin: 10px 0;
    list-style-type: none;
    counter-increment: strength_nav 1;

    @include sp{
      width: 48%;
      margin: 5px 0;
    }

    &:before{
      content: "0" counter(strength_nav);
      position: absolute;
      left: -5px;
      top: 7px;
      z-index: 2;
      width: 32px;
      border-radius: 16px;
      text-align: center;
      font-family: $roboto;
      @include fz(13);
      line-height: 32px;
      color: #fff;
      pointer-events: none;

      @include sp{
        top: 12px;
        width: 23px;
        line-height: 23px;
        @include fz(11);
      }

    }

    a{
      position: relative;
      display: block;
      background: #fff;
      border-radius: 100px;
      text-align: center;
      @include fz(13);
      font-weight: bold;
      line-height: 46px;

      @include sp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 46px;
        @include fz(12);
        line-height: 1.4;
      }

      &:after{
        position: absolute;
        right: 15px;
        top: 50%;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 0;
        transform: rotate(90deg);

        @include sp{
          right: 10px;
          width: 6px;
          height: 6px;
          margin-top: -3px;
        }

      }

    }

  }

  &_sec{
    margin: 80px auto 0;
    counter-increment: strength_sec 1;
    @include sp{
      margin-top: 50px;
    }
    &:not(#strength05) {
      width: 906px;
      @include sp {
        width: 100%;
      }
    }
    &#strength05 {
      .l_strength_ttl,
      .l_strength_inner {
        width: 906px;
        margin-left: auto;
        margin-right: auto;
        @include sp {
          width: 100%;
        }
      }
    }

  }

  &_ttl{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 181px;
    border-radius: 4px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 3px 3px 26px rgba(0, 0, 0, 0.13);

    @include sp{
      flex-direction: row;
      align-items: center;
      height: auto;
      padding: 15px;
      box-shadow: none;
      border-radius: 4px;
      background-size: 100% auto;
    }

    &:before{
      content: "0" counter(strength_sec);
      display: inline-block;
      width: 32px;
      border-radius: 16px;
      background: #fff;
      font-family: $roboto;
      @include fz(18);
      line-height: 32px;
      text-align: center;
      font-weight: bold;

      @include sp{
        width: 26px;
        margin: 0 10px 0 0;
        @include fz(14);
        line-height: 26px;
      }

    }

    &_main{
      display: inline-block;
      color: #fff;
      font-weight: bold;
      @include fz(44);
      text-shadow: 0px 3px 6px rgba(10, 89, 118, 0.3);
      line-height: 1.4;

      @include sp{
        @include fz(26);
      }

    }

  }

  &_photo{

    @include sp{
      margin-left: -20px;
      margin-right: -20px;

      .slick-slide{
        margin: 0 5px;
      }

      .slick-prev,
      .slick-next{
        z-index: 5;
        top: 50%;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: $color_1;

        &:before{
          position: absolute;
          left: 50%;
          top: 50%;
          @include triangle(fff);
          opacity: 1;
          width: 9px;
          height: 8px;
          margin: -5px 0 0 -4px;
          font-size: 0;

        }

      }
      .slick-prev{
        left: 10px;
        transform: translate(0,-50%) scaleX(-1);
      }
      .slick-next{
        right: 10px;
      }

    }

  }

  &_aobtn{
    width: 758px;
    height: 234px;
    margin: 80px auto 0;

    @include sp{
      width: auto;
      height: 234px;
      margin: 64px 0 0;
      border-radius: 4px;
      overflow: hidden;
      background-size: cover;
    }

    >a{
      position: relative;
      display: block;
      height: 100%;
      padding: 32px 20px 20px 290px;
      background: url(#{$img_dir}/common/strength_aobtn_bg.png) no-repeat;
      transition: .4s ease;

      @include sp{
        padding: 20px 24px 0;
        background: url(#{$img_dir}/common/sp/strength_aobtn_bg.png) no-repeat center center;
      }

      &:before{
        content: "";
        position: absolute;
        left: 50px;
        top: -43px;
        width: 203px;
        height: 277px;
        background: url(#{$img_dir}/common/strength_aobtn_photo.png) no-repeat;
        background-size: contain;
        pointer-events: none;

        @include sp{
          top: auto;
          bottom: 0;
          left: -20px;
          width: 119px;
          height: 163px;
          background-position: left bottom;
        }

      }

      &:hover{

        @include pc{
          opacity: .5;
        }

      }

    }

    &_ttl{
      display: block;
      width: 342px;

      @include sp{
        width: 100%;
      }

    }

    &_list{
      margin: 24px 0 0 0;

      @include sp{
        margin-left: 75px;
      }

    }
    &_item{
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 0 10px;
      background: #fff;
      border-radius: 15px;
      line-height: 27px;
      @include fz(14);
      font-weight: bold;

      @include sp{
        margin: 0 0 8px 0;
        @include fz(12);
        line-height: 23px;
        white-space: nowrap;
      }

    }

    &_link{
      position: absolute;
      right: 20px;
      bottom: 20px;

      @include sp{
        right: 10px;
        bottom: 10px;
        line-height: 20px !important;
      }

    }

  }

  &_equipment{
    gap: 40px 30px;

    .c_card{
      width: calc(50% - 15px);

      @include sp{
        width: auto;
      }

    }

  }

  &_360{
    width: 758px;
    height: 160px;
    margin: 40px auto 0;
    background: url(#{$img_dir}/common/strength_360_bg.jpg) no-repeat;
    background-size: cover;

    @include sp{
      width: auto;
      height: 140px;
      margin-top: 30px;
      padding: 20px 0;
      background-image: url(#{$img_dir}/common/sp/strength_360_bg.jpg);
      text-align: center;
    }

    &_link{
      display: block;
      line-height: 160px;
      text-align: center;
      @include a_tag(#fff,#fff);
      @include fz(28);
      font-weight: bold;

      @include sp{
        @include fz(18);
        line-height: 20px;

        span{
          position: relative;
          display: inline-block;
          padding: 0 25px 0 0;

          &:before{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background: #fff;
          }

          &:after{
            content: "";
            position: absolute;
            right: 6px;
            top: 50%;
            width: 7px;
            height: 7px;
            margin-top: -3.5px;
            @include triangle(222);
          }

        }

      }

      img{
        width: 58px;
        height: 58px;
        margin: 0 10px 0 0;
        vertical-align: middle;

        @include sp{
          margin: 0 0 15px 0;
        }

      }

      &:before{
        top: 50%;
        right: 20px;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        background: #fff;

        @include sp{
          display: none;
        }

      }

      &:after{
        right: 28px;
        top: 50%;
        width: 11px;
        height: 11px;
        margin-top: -5.5px;
        @include triangle(222);

        @include sp{
          display: none;
        }

      }

    }

  }

  &_curriculum{
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;

    @include sp{
      display: block;
      margin-top: 15px;
    }

    &_required{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 333px;
      max-width: 333px;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      @include fz(26);
      font-weight: bold;
      line-height: 1.7;
      text-align: center;

      @include sp{
        min-width: initial;
        max-width: initial;
        padding: 20px;
        @include fz(16);
      }

      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 68px;
        margin-bottom: 10px;
        background: url(#{$img_dir}/common/strength_curriculum.svg) no-repeat center top;
        background-size: auto 100%;

        @include sp{
          height: 45px;
        }

      }

    }

    &_select{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 0 88px;
      padding: 20px 30px 20px;
      border: 2px solid;
      border-radius: 4px;

      @include sp{
        margin: 60px 0 0 0;
        padding: 20px;
      }

      &:before,
      &:after{
        content: "";
        position: absolute;
        top: 50%;
        background: $color_1;

        @include sp{
        }

      }
      &:before{
        left: -49px;
        width: 10px;
        height: 50px;
        margin-top: -25px;

        @include sp{
          left: 50%;
          top: -50px;
          width: 8px;
          height: 40px;
          margin: 0 0 0 -4px;
        }

      }
      &:after{
        left: -69px;
        width: 50px;
        height: 10px;
        margin-top: -5px;

        @include sp{
          left: 50%;
          top: -34px;
          width: 40px;
          height: 8px;
          margin: 0 0 0 -20px;
        }

      }

      &_ttl{
        margin: 0 10px;
        border-radius: 100px;
        @include fz(18);
        font-weight: bold;
        line-height: 38px;
        text-align: center;
        color: #fff;

        @include sp{
          margin: 0;
          @include fz(16);
        }

      }

      &_txt{
        margin: 15px 0 0 0;
        font-weight: bold;
        @include fz(14);

        @include sp{
          margin-top: 10px;
        }

      }

    }

  }

  &_free{
    position: relative;
    min-height: 410px;
    padding: 40px 40px 40px 23px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp {
      display: block;
      min-height: initial;
      padding: 45px 20px 40px;
    }

    &_wrap{
      margin: 30px 0 0 0;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: left top;
      background-size: cover;
      text-align: center;

      @include sp{
        margin: 20px -20px 0;
        border-radius: 0;
      }

      &_inner{
        max-width: 980px;
        margin: 0 auto;
      }

    }

    &_img{
      width: 100%;
      height: auto;
      &_box {
        flex-basis: 351px;
        @include sp {
          position: absolute;
          left: 5px;
          top: 40px;
          width: 120px;
        }
      }
    }

    &_cont_box {
      flex: 0 1 auto;
    }

    &_txt{
      position: relative;
      margin: 0 0 0 -16px;
      padding: 0 0 0 32px;
      @include fz(28);
      font-weight: bold;
      color: #fff;
      line-height: 1.55;
      text-align: left;

      @include sp{
        margin: 0 0 0 100px;
        padding: 0 0 0 22px;
        font-size: 1.6rem !important;
      }

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 19px;
        height: 100%;
        background: url(#{$img_dir}/common/strength_free_bubble.svg) no-repeat left center;
        background-size: 19px auto;

        @include sp{
          width: 12px;
          background-size: 12px auto;
        }

      }

      &_wrap{

        @include sp{
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 120px;
        }

      }

      b{
        background: linear-gradient(transparent 70%, #FFBD40 70%);
      }

    }

    &-imgright{
      padding: 40px 23px 40px 40px;
      flex-direction: row-reverse;
      @include sp{
        padding: 45px 20px 40px;
      }

      .l_strength_free_img{
        left: auto;
        right: 15px;

        @include sp{
          right: auto;
          left: 5px;
        }

      }

      .l_strength_free_txt{
        margin: 0 -16px 0 0;
        padding: 0 32px 0 0;

        @include sp{
          margin: 0 0 0 100px;
          padding: 0 0 0 22px;
        }

        &:before{
          left: auto;
          right: 0;
          transform: scaleX(-1);

          @include sp{
            left: 0;
            right: auto;
            transform: none;
          }

        }

      }

    }

    &_examples{
      display: flex;
      margin: 20px 0 0 0;
      gap: 30px;

      @include sp{
        justify-content: space-between;
        gap: 0;
        margin: 13vw 0 0 0;
      }

    }
    &_example{
      width: 155px;
      text-align: center;
      color: #fff;
      @include fz(16);
      font-weight: bold;

      @include sp{
        width: calc(33.33% - 7px);
        font-size: 1.4rem !important;
      }

      img{
        margin: 0 0 10px 0;

        @include sp{
          margin-bottom: 5px;
        }

      }

    }

    &_note{
      margin: 5px 0 0 0;
      text-align: right;
      @include fz(12);

      @include sp{
        margin-left: 20px;
        margin-right: 20px;
        padding: 0 0 0 1em;
        text-indent: -1em;
        text-align: left;
        font-size: 1.2rem !important;
      }

    }

  }

  &_campuslife{
    position: relative;
    margin: 115px -80px 0 -300px;

    @include sp{
      margin: 66px -20px 0;
    }

    &:before{
      content: "";
      position: absolute;
      z-index: 2;
      left: calc(50% + 100px);
      top: -36px;
      max-width: 906px;
      width: 100%;
      height: 80px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateX(-50%);
      @include mq_max(1365) {
        left: 300px;
        transform: translateX(0);
      }

      @include sp{
        max-width: initial;
        height: 50px;
        left: 0;
        top: -25px;
      }

    }

    &_slider{
      overflow: hidden;
      width: 100%;

      &_image{
        width: auto;
        height: 100%;
        object-fit: contain;
      }

    }

    .slick-slide{
      margin: 0 8px;
      width: 567px;
      height: auto;

      @include sp{
        margin: 0 4px;
        width: 293px;
        height: auto;
      }

      img{
        object-fit: cover;
      }

    }

  }

}

/* =================================
 l_course
================================= */
.l_course{

  &_future{
    padding: 25px 30px 30px;
    border-radius: 4px;
    text-align: center;

    @include sp{
      padding: 20px 10px;
    }

    &_ttl{
      display: inline-block;
      padding: 0;
      @include fz(21);
      font-weight: bold;
      line-height: 1;

      @include sp{
        @include fz(16);
      }

      &:before,
      &:after{
        content: "";
        display: inline-block;
        width: 2px;
        height: 34px;
        background: $color_1;
        vertical-align: bottom;
        transform-origin: center bottom;

        @include sp{
          height: 28px;
        }

      }
      &:before{
        margin-right: 20px;
        transform: rotate(-30deg) translateY(2px);
      }
      &:after{
        margin-left: 20px;
        transform: rotate(30deg) translateY(2px);
      }

    }

    &_txt{
      margin: 20px 0 0 0;
      @include fz(14);
      font-weight: bold;
      line-height: 1.75;
    }

  }

  &_list{
    position: relative;
    padding: 60px;
    background: #F5F5F5;

    @include sp{
      margin-left: -20px;
      margin-right: -20px;
      padding: 60px 20px 40px;

      .c_media{
        display: flex;
        flex-direction: column-reverse;

        &_txt{
          margin: 0;
        }

        &_img{
          margin-top: 20px;
        }

      }

    }

    &._photographer {
      padding-top: 0;
      background: none;
      >div {
        margin: 0 -220px;
        padding: 60px 160px;
        &:nth-child(odd) {
          background: rgba(170,212,76,.2);
        }
      }
    }

    &_ttl{
      position: absolute;
      left: 0;
      top: 0;
      padding: 13px 20px;
      transform: translateY(-50%);
      @include fz(30);
      font-weight: bold;
      line-height: 1;
      color: #fff;

      @include sp{
        left: 20px;
        padding: 18px 15px;
        @include fz(22);
      }

    }

  }

  &_curriculum{

    @include sp{
      margin-left: -20px;
      margin-right: -20px;

      .slick-slide{
        margin: 0 10px;
      }

      .slick-prev,
      .slick-next{
        z-index: 5;
        top: 30vw;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: $color_1;

        &:before{
          position: absolute;
          left: 50%;
          top: 50%;
          @include triangle(fff);
          opacity: 1;
          width: 9px;
          height: 8px;
          margin: -5px 0 0 -4px;
          font-size: 0;

        }

      }
      .slick-prev{
        left: 15px;
        transform: translate(0,-50%) scaleX(-1);
      }
      .slick-next{
        right: 15px;
      }

    }

  }

  &_interview{

    @include sp{
      margin-left: -20px;
      margin-right: -20px;

      .c_card{
        margin-left: 5px;
        margin-right: 5px;

        &_ttl{
          margin-top: 7px;
        }

        &_txt{
          margin-top: 5px;
        }

      }

    }

  }

  &_work{
    margin: 20px -160px 0;
    padding: 40px 160px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include sp{
      margin: 15px -20px 0;
      padding: 30px 20px;
    }

    &_note{
      margin: 10px 0 0 0;
      @include fz(12);
      color: #fff;
      line-height: 1.65;

      &_item{
        padding: 0 0 0 1em;
        text-indent: -1em;

        &:before{
          content: "※";
          text-indent: 0;
        }

      }

    }

    &_lists{
      margin: 25px 0 0 0;
      padding: 25px 40px 30px;
      border-radius: 4px;
      background: #fff;

      @include sp{
        margin-top: 20px;
        padding: 20px 15px;
      }
      &.l_content_min_width {
        @include sp {
          padding: 20px 15px !important;
        }
      }

      &_ttl{
        text-align: center;
        @include fz(21);
        font-weight: bold;

        @include sp{
          @include fz(18);
        }

      }

    }

    &_list{
      width: 30%;
      margin: 25px 0 0 0;
      @include fz(14);
      line-height: 1.75;

      @include sp{
        width: auto;
        margin: 0;
        @include fz(12);
        line-height: 1.65;
        text-align: left;
      }

      &_wrap{
        display: flex;
        justify-content: space-between;

        @include sp{
          position: relative;
          display: block;
          max-height: 320px;
          overflow: hidden;
          margin: 15px 0 0 0;
          transition: .4s;

          &:after{
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.83) 46.8%, #fff 100%);
          }

          &.is-show {
            max-height: initial;
            padding-bottom: 30px;

            &:after{
              display: none;
            }

          }

        }

      }

    }

    &_more{
      display: none;

      @include sp{
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        padding-right: 25px;
        @include fz(16);
        white-space: nowrap;

        &:after{
          transform: rotate(90deg) translateX(2px);
        }
        &.is-show {
          &:after {
            transform: rotate(-90deg) translateX(0) translateY(-1px);
          }
        }
      }

    }

  }

  &_license{
    width: calc(50% - 20px);

    @include sp{
      width: auto;

      &+&{
        margin-top: 20px;
      }

    }

    &_wrap{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
      margin: 20px 0 0 0;
      padding: 40px;
      background: #F5F5F5;

      @include sp{
        display: block;
        margin: 15px 0 0 0;
        padding: 20px 15px;
      }

    }

    &_ttl{
      display: block;
      padding: 7px 10px;
      border-radius: 4px;
      color: #fff;
      font-weight: bold;
    }

    &_txt{
      margin: 10px 0 0 0;
      @include fz(14);
    }

    &_society{
      margin: 10px 0 0 0;
      text-align: right;
      font-weight: bold;
      @include fz(12);
    }

  }

  &_player{
    margin: 20px 0 0 0;

    @include sp{
      margin: 15px 0 0 0;

      .c_media{
        display: flex;
        flex-direction: column-reverse;

        &_txt{
          margin: 0;
        }

        &_img{
          margin-top: 20px;
        }

      }

    }

    &_ttl{
      position: relative;
      padding: 20px;
      border-radius: 4px 4px 0 0;
      color: #fff;
      text-align: center;

      @include sp{
        padding: 13px 30px;
        @include fz(14);
        line-height: 1.4;
      }

      &:after{
        content: "";
        position: absolute;
        z-index: 2;
        left: 50%;
        bottom: -18px;
        margin-left: -14px;
        border-right: 17px solid;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-90deg);
      }

    }

    &_inner{
      padding: 40px 60px 60px 60px;
      background: #f5f5f5;

      @include sp{
        padding: 30px 15px 40px;
      }

    }

  }

  &_other{
    width: calc(50% - 20px);
    border-radius: 4px;

    @include sp{
      width: auto;
    }

    &_wrap{
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin: 20px 0 0 0;

      @include sp{
        display: block;
        margin: 15px 0 0 0;
      }

    }

    &_link{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      @include a_tag(#fff,#fff);
    }

    &_img{
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: rgba($color_1,.5);
      }

    }

    &_txt{
      position: absolute;
      z-index: 3;
      left: 20px;
      bottom: 20px;
      width: calc(100% - 40px);
      @include fz(22);
      font-weight: bold;
      line-height: 52px;

      @include sp{
        left: 15px;
        bottom: 15px;
        line-height: 38px;
        font-weight: 1.8rem !important;
      }

      &:before{
        content: "";
        display: inline-block;
        width: 52px;
        height: 52px;
        margin: 0 20px 0 0;
        border-radius: 26px;
        vertical-align: middle;

        @include sp{
          width: 38px;
          height: 38px;
          margin: 0 15px 0 0;
        }

      }

      &:after{
        content: "";
        position: absolute;
        left: 16px;
        top: 16px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #fff;

        @include sp{
          left: 12px;
          top: 12px;
          width: 14px;
          height: 14px;
        }

      }

    }

  }

}

.l_course_other_wrap-3{
  gap: 30px;

  .l_course_other{
    width: calc(33.33% - 20px);

    @include sp{
      width: auto;

      &+.l_course_other{
        margin-top: 20px;
      }

    }

    &_txt{
      left: 15px;
      bottom: 15px;
      width: calc(100% - 30px);
      @include fz(20);
      line-height: 34px;

      &:before{
        content: "";
        display: inline-block;
        width: 34px;
        height: 34px;
        margin: 0 12px 0 0;
        border-radius: 17px;
        vertical-align: top;
      }

      &:after{
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        width: 14px;
        height: 14px;
        border-radius: 7px;
      }

    }

  }

}

.l_course_other{

  @include sp{

    & + & {
      margin-top: 20px;
    }

  }
}


/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  margin: 0 0 0 auto;

  @include sp{
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    margin-bottom: -10px;
  }

  &_list{
    text-align: right;
    font-size: 0;
    white-space: nowrap;
  }

  &_item{
    display: inline-block;
    @include fz(13);

    &:after{
      content: "-";
      margin: 0 7px;
    }

    &:last-of-type{
      opacity: .5;

      &:after{
        display: none;
      }

    }

    a{
      color: $color_1;
      transition: .4s ease;

      &:hover{

        @include pc{
          opacity: .4;
        }

      }

    }

  }

}

/* =================================
 l_photomenu
================================= */
.l_photomenu{
  margin: 80px auto 0;
  padding: 80px 0 0 0;
  border-top: 2px solid $color_3;

  @include sp{
    margin-top: 50px;
    padding-top: 50px;
  }

  &_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;

    &:before,
    &:after{
      content: "";
      display: block;
      width: calc(25% - 12px);

      @include sp{
        width: calc(50% - 7.5px);
      }

    }
    &:before{
      order: 1;

      @include sp{
        display: none;
      }

    }

  }

  &_item{
    width: calc(25% - 12px);

    @include sp{
      width: calc(50% - 7.5px);
    }

    a{
      display: block;

      &:hover .c_link:after{

        @include pc{
          transform: translateX(30%);
        }

      }

    }

    .c_link{
      margin: 10px 0 0 0;
      @include fz(14);
      line-height: 20px;

      @include sp{
        margin-top: 7px;
        line-height: 15px;
      }

      &:before{
        width: 20px;
        height: 20px;

        @include sp{
          width: 15px;
          height: 15px;
        }

      }

      &:after{
        right: 6px;
        top: 7px;
        width: 6px;
        height: 4px;
        transition: .4s ease;

        @include sp{
          right: 4px;
          top: 5px;
          width: 5px;
          height: 5px;
        }

      }

      &:hover:after{
        transform: none;
      }

    }

  }

  &_img{
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000,.3);
    }

  }

}

/* =================================
 l_ao_btn
================================= */
.l_ao_btn {
  position: relative;
  display: inline-block;
  width: 758px;
  height: 160px;
  border-radius: 4px;
  background: linear-gradient(to right, $color_31 0%, $color_32 100%);
  font-weight: bold;
  @include a_tag($color_21,$color_21);
  text-align: center;
  transition: .4s ease;

  @include sp{
    display: block;
    width: 100%;
    height: 120px;
    @include fz(16);
  }

  &_inner{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 0 0 60px;

    @include sp{
      padding: 0 30px 0 120px;
    }

    &:before{
      content: "";
      position: absolute;
      left: 20px;
      top: 0;
      width: 140px;
      height: 151px;
      background: url(#{$img_dir}/entrance/ao25/online.svg) no-repeat center top;
      background-size: auto 100%;

      @include sp{
        top: 0;
        left: 8px;
        width: 104px;
        height: 112px;
      }

    }

  }

  &_lead{
    @include fz(43);

    @include sp{
      @include fz(20);
    }

  }

  &_txt{
    display: block;
    margin: 20px 0 0 0;
    @include fz(20);

    @include sp{
      margin: 10px 0 0 0;
      @include fz(16);
      line-height: 1.5;
    }

  }

  &:before{
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border-radius: 15px;
    background: $color_21;

    @include sp{
      right: 5px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }

  }

  &:after{
    content: "";
    position: absolute;
    right: 29px;
    top: 50%;
    @include triangle(CA5700);
    width: 11px;
    height: 9px;
    margin-top: -6px;
    transition: .4s ease;

    @include sp{
      width: 8px;
      height: 8px;
      right: 10px;
      margin-top: -4px;
    }

  }

  @include pc{

    &:hover{
      opacity: .5;
    }

  }

  &_wrap{
    margin: 80px 0 0 0;
    text-align: center;

    @include sp{
      margin-top: 50px;
    }

  }

}

/* =================================
 l_page_top
================================= */
.l_page_top {
  position: fixed;
  z-index: 50;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid $color_1;
  background: #fff;
  margin: 0 0 0 auto;
  cursor: pointer;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 15px 9px;
    border-color: transparent transparent $color_1 transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: .4s ease;
  }
  &:hover {
    &:before {
      @include pc {
        transform: translate(-50%,calc(-50% - 7px));
      }
    }
  }
  @include sp {
    right: 10px;
    bottom: 78px;
  }
}

/* =================================
 l_sp_fixed_menu
================================= */
.l_sp_fixed_menu {
  display: none;
  @include sp {
    background: #fff;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    align-items: stretch;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    &__btn {
      @include a_tag(#fff,#fff);
      @include fz(12);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 48px;
      &:before {
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin: 0 5px 0 0;
      }
      &.__doc {
        background: linear-gradient(to right, #FF6E00 0%,#C75600 100%);
        &:before {
          width: 19px;
          height: 19px;
          background-image: url(../img/common/l_sp_fixed_doc_icon.svg);
        }
      }
      &.__oc {
        background: linear-gradient(to right, #8DDBF7 0%,#1BACC7 100%);
        &:before {
          width: 21px;
          height: 21px;
          background-image: url(../img/common/l_sp_fixed_oc_icon.svg);
        }
      }
      &.__line {
        background: linear-gradient(to right, #25DE25 0%,#039D03 100%);
        &:before {
          width: 22px;
          height: 20px;
          background-image: url(../img/common/l_sp_fixed_line_icon.svg);
        }
      }
    }
  }
}

/* =================================
 l_header_top
================================= */
.l_header_top {
}

/* =================================
 l_header_sp_top
================================= */
.l_header_sp_top {
}

/* =================================
 l_main_visual_top
================================= */
.l_main_visual_top {
}

/* =================================
 l_content_top
================================= */
.l_content_top {
}

/* =================================
 l_main_top
================================= */
.l_main_top {
}

/* =================================
 l_side_top
================================= */
.l_side_top {
}

/* =================================
 l_footer_top
================================= */
.l_footer_top {
}

/* =================================
 l_hoge_top
================================= */
.l_hoge_top {
}

@use 'setting' as *;

/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl {

  &_sub{
    display: block;

    @include sp{
      line-height: 1.3;
    }

    &.u_en{
      color: $color_3;
    }

  }

  &_main{
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:before{
      position: absolute;
      left: -20px;
      top: 50%;
      content: "";
      width: 10px;
      height: 10px;
      margin-top: -5px;
      border-radius: 5px;
      background: $color_4;

      @include sp{
        width: 7px;
        height: 7px;
        margin-top: -3.5px;
      }

    }

  }

  &_lead{
    display: inline-block;
    margin: 0 0 0 30px;
    @include fz(18);
    font-weight: bold;
    vertical-align: middle;

    @include sp{
      display: none;
    }

  }

  &-xs{

    .c_ttl_main{
      padding: 0 0 0 15px;
      @include fz(22);

      @include sp{
        padding: 0;
        @include fz(20);
      }

      &:before{
        left: 0;

        @include sp{
          left: -12px;
        }

      }

    }

  }

  &-s{

    .c_ttl_sub{
      @include fz(15);
      font-weight: bold;

      @include sp{
        @include fz(11);
      }

    }

    .c_ttl_main{
      padding: 7px 0 15px;
      @include fz(40);
      font-weight: bold;

      @include sp{
        padding-top: 0;
        padding-bottom: 7px;
        @include fz(26);
        line-height: 1.6;
      }

      &:before{
        top: 30px;

        @include sp{
          top: 22px;
          left: -15px;
        }

      }

    }

  }

  &-m{

    .c_ttl_sub{
      @include fz(12);
      font-weight: bold;
    }

    .c_ttl_main{
      padding: 7px 0 15px;
      @include fz(46);
      font-weight: bold;

      &.u_en{
        @include fz(60);
        font-weight: bold;
      }

      @include sp{
        padding: 2px 0 10px;
        @include fz(44);
        line-height: 1.3;

        &:before{
          top: 27px;
          left: -12px;
          margin-top: 0;
        }

      }

    }

  }

  &-l{

    .c_ttl_sub{
      @include fz(12);
      font-weight: bold;
    }

    .c_ttl_main{
      padding: 8px 0 0 0;
      @include fz(40);
      font-weight: bold;

      &:before{
        top: 29px;
        left: -15px;
        margin-top: 0;
        width: 6px;
        height: 6px;
      }

      @include sp{
        padding-top: 0;
        @include fz(28);
        line-height: 1.6;

        &:before{
          top: 22px;
          left: -12px;
        }

      }

    }

  }

  &-system{
    @include fz(30);
    font-weight: bold;
    line-height: 1.45;

    @include sp{
      @include fz(22);
      line-height: 1.6;
    }

  }

  &-center{
    text-align: center;
    @include fz(30);

    @include sp{
      @include fz(24);
    }

  }

  &-withimg,
  &-point,
  &-colorborder{
    position: relative;
    padding: 0 0 25px 0;
    border-bottom: 3px solid $color_3;
    @include fz(30);
    font-weight: bold;

    @include sp{
      padding-bottom: 10px;
      @include fz(24);
    }

    &:before{
      content: "";
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 90px;
      height: 3px;
      background: $color_4;
    }

    .c_ttl_main:before{
      display: none;
    }

  }
  &_img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 135px;

    @include sp{
      display: none;
    }

  }

  &_circle{
    position: relative;
    display: inline-block;
    width: 65px;
    height: 65px;
    margin: 0 20px 0 0;
    border-radius: 33px;
    vertical-align: middle;

    @include sp{
      width: 29px;
      height: 29px;
      margin-right: 5px;
    }

    &:before{
      content: "";
      position: absolute;
      left: 20px;
      top: 20px;
      width: 25px;
      height: 25px;
      border-radius: 13px;
      background: $color_21;

      @include sp{
        left: 9px;
        top: 9px;
        width: 11px;
        height: 11px;
      }

    }

  }

  &-point{
    position: relative;
    padding: 0;
    border-bottom: none;

    &:before{
      z-index: 3;
      bottom: 0;
    }

    &:after{
      content: attr(data-bg);
      position: absolute;
      right: 0;
      bottom: -20px;
      @include fz(190);
      line-height: 1;
      font-family: $roboto;
      color: $color_21;
      -webkit-text-stroke: 3px $color_23;

      @include sp{
        display: none;
      }

    }

    &_inner{
      position: relative;
      z-index: 2;
      display: flex;
      padding: 0 0 25px 0;
      border-bottom: 3px solid $color_3;
      align-items: center;
      line-height: 1.33;

      @include sp{
        align-items: flex-start;
      }

    }

    .c_ttl_main{
      z-index: 2;
    }

    &-nobg{

      .c_ttl-point_inner{
        padding: 0 0 20px 0;

        @include sp{
          padding-bottom: 10px;
        }

      }

      .c_ttl_main{

        @include sp{
          padding-top: 3px;
        }

      }

    }

  }
  &_point{
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 65px;
    height: 65px;
    margin: 0 20px 0 0;
    padding: 5px 0 0 0;
    border-radius: 4px;
    vertical-align: middle;
    color: $color_21;
    @include fz(15);
    line-height: 1;
    font-family: $roboto;
    text-align: center;
    font-weight: bold;

    @include sp{
      width: 40px;
      height: 40px;
      max-width: 40px;
      min-width: 40px;
      margin: 0 5px 0 0;
      @include fz(9);
      white-space: nowrap;
      line-height: 0.8;
    }

    &-num{
      display: block;
      @include fz(40);
      line-height: 1;

      @include sp{
        @include fz(24);
      }

    }

  }
  &-colorborder{
    padding-bottom: 20px;
    line-height: 1.4;

    @include sp{
      padding-bottom: 10px;
    }

    .c_ttl_sub{
      @include fz(16);
      color: $color_24;

      @include sp{
        @include fz(14);
      }

    }

  }

  &-leftborder{
    padding: 0 0 0 17px;
    border-left: 3px solid $color_4;
    @include fz(26);
    font-weight: bold;
    line-height: 1.4;

    @include sp{
      padding-left: 10px;
      @include fz(22);
    }

    .c_ttl_main:before{
      display: none;
    }

  }

  &-border{
    border-bottom: 2px solid $color_1;

    @include sp{
      border-bottom-width: 1px;
    }

  }

  &-animation{

    .c_ttl_main span{
      display: inline-block;
      opacity: 0;
      transition: 0.6s ease;
      transition-timing-function: cubic-bezier(.165,.84,.44,1);
      transform: translateY(50%);

      @for $i from 1 through 100 {
        &:nth-of-type(#{$i}) {
          transition-delay: #{$i*.1}s;
        }
      }

    }

    &.is-show .c_ttl_main span{
      opacity: 1;
      transform: translateY(0);
    }

  }

  &_small{
    padding-left: 1em;
    @include fz(16);
  }

}


/* =================================
 link
================================= */
.c_link {
  position: relative;
  @include fz(14);
  font-weight: bold;
  line-height: 30px;

  @include sp{
    line-height: 20px;
  }

  &:before{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: $color_1;

    @include sp{
      width: 20px;
      height: 20px;
    }

  }

  &:after{
    content: "";
    position: absolute;
    right: 9px;
    top: 9px;
    @include triangle(fff);
    width: 10px;
    height: 10px;
    transition: .4s ease;

    @include sp{
      width: 7px;
      height: 6px;
      right: 6px;
      top: 6px;
    }

  }

  @include pc{

    &:hover:after{
      transform: translateX(30%);
    }

  }

  &-lr{

  }

  &-r{
    padding: 0 40px 0 0;
    text-align: right;

    @include sp{
      padding: 0 30px 0 0;
    }

  }

  &-disabled{
    pointer-events: none;

    &:before,
    &:after{
      display: none;
    }

  }

}

.c_link_w {
  position: relative;
  padding: 0 0 0 32px;
  @include fz(14);
  font-weight: bold;
  line-height: 20px;

  @include sp{
    line-height: 20px;
  }

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid $color_21;
    box-sizing: border-box;

    @include sp{
      width: 20px;
      height: 20px;
    }

  }

  &:after{
    position: absolute;
    left: 8px;
    top: 6px;
    @include triangle(fff);
    width: 6px;
    height: 4px;
    transition: .4s ease;

    @include sp{
    }

  }

  &:hover:after{
    transform: translateX(30%);
  }

}

.c_dammy_link {
  opacity: .4;
  pointer-events: none;
}

/* =================================
 buttons
================================= */
.c_btn {
  position: relative;
  display: inline-block;
  padding: 24px 70px;
  border-radius: 100px;
  background: $color_1;
  color: $color_21;
  @include a_tag($color_21,$color_21);
  @include fz(16);
  font-weight: bold;
  line-height: 1;
  overflow: hidden;
  transition: .4s ease;

  @include sp{
    display: block;
    padding: 24px 20px;
    text-align: center;
  }

  &:before{
    content: attr(data-text)"　"attr(data-text)"　"attr(data-text)"　"attr(data-text)"　"attr(data-text)"　"attr(data-text)"　"attr(data-text)"　"attr(data-text)"　";
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    width: 100%;
    padding: 24px 0;
    opacity: 0;
    transition: transform 10s linear;
  }

  &:after{
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    border-radius: 5px;
    background: $color_4;
    transition: .4s ease;
  }

  &:hover{

    @include pc{
      background: $color_25;

      .c_btn_inner{
        opacity: 0;
      }

      &:before{
        opacity: 1;
        transform: translate(-1000px);
      }

      &:after{
        opacity: 0;
      }

    }

  }

  &_wrap{
    text-align: center;
  }

  &-s{
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    @include fz(13);
    overflow: hidden;
    transition: .4s ease;

    &:before{
      padding: 5px 0;
    }

    &:after{
      display: none;
    }

  }

  &-m{
    padding: 19px 80px;

    &:before{
      padding: 19px 0;
    }

  }

  &-return{
    margin-top: 30px;
    border: 1px solid $color_1;
    background: $color_21;
    @include a_tag($color_1,$color_1);
    color: $color_1;

    @include sp{
      margin-top: 20px;
    }

    &:before{
      padding: 15px 0;
    }

    &:after{
      right: 10px;
    }

  }

  &-down{
    width: 212px;
    padding: 15px 0;
    border: 1px solid $color_1;
    background: $color_21;
    @include a_tag($color_1,$color_1);
    color: $color_1;
    @include fz(13);
    text-align: center;

    @include sp{
      margin-left: auto;
      margin-right: auto;
    }

    &:before{
      padding: 15px 0;
    }

    &:after{
      right: 10px;
      width: 9px;
      height: 9px;
      background: none;
      @include triangle(222);
      transform: rotate(90deg);
    }

  }

  &-disabled{
    pointer-events: none;
    background: $color_26;

    &:after{
      background: $color_27 !important;
    }

  }

}


/* =================================
 cat
================================= */
.c_cat {

  &-all{
    background: $gradient_all;
  }

  &-musician{
    background: $gradient_musician;
  }

  &-performing{
    background: $gradient_performing;
  }

  &-sound{
    background: $gradient_sound;
  }

  &-movie{
    background: $gradient_movie;
  }

  &-photo{
    background: $gradient_photo;
  }

}


/* =================================
 pagination
================================= */
.c_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 0 0;

  @include sp{
    margin-top: 30px;
  }

  &_prev,
  &_current,
  &_num,
  &_next{
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 0 5px;
    line-height: 38px;
    text-align: center;
    font-family: $roboto;
    @include fz(18);
    font-weight: bold;

    @include sp{
      width: 36px;
      height: 36px;
      margin: 0 7px;
      line-height: 34px;
      @include fz(16);
    }

  }

  &_current{
    border: 1px solid $color_1;
  }
  &_num{
    transition: .4s ease;

    &:hover{
      opacity: .2;
    }

  }
  &_next,
  &_prev{
    position: relative;
    transition: .4s ease;

    &:after{
      @include triangle(222);
      position: absolute;
      width: 12px;
      height: 10px;
      left: 15px;
      top: 13px;

      @include sp{
        left: 14px;
        top: 13px;
        width: 9px;
        height: 9px;
      }

    }

    &:hover{

      @include pc{
        background: $color_1;

        &:after{
          content: "";
          left: 16px;
          top: 14px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 10px;
          border-color: transparent transparent transparent $color_21;
          transition: none;
        }

      }

    }

  }
  &_prev{
    transform: scaleX(-1);
  }

}


/* =================================
 icon
================================= */
.c_ico_1 {
  // _pt1
  &._pt1 {}
  // _pt2
  &._pt2 {}
}

.c_ico_2 {
}

.c_ico_3 {
}

/* =================================
 alerts
================================= */
.c_alerts_1 {
}

.c_alerts_2 {
}

.c_alerts_3 {
}

/* =================================
 pager
================================= */
.c_pager_num {
}

.c_pager_prev_next {
}

/* =================================
 table
================================= */
.c_table_simple {

  &-head,&-data{
    padding: 20px;
    border-bottom: 1px solid $color_28;
    vertical-align: top;
    text-align: left;
    line-height: 2;

    @include sp{
      display: block;
      padding-left: 0;
      padding-right: 0;
    }

  }
  &-head{
    min-width: 190px;
    white-space: nowrap;
    font-weight: bold;

    @include sp{
      min-width: initial;
      border-bottom: none;
      padding-bottom: 0;
    }

    &_small{
      font-weight: normal;
      @include fz(14);
    }

  }
  &-data{
    padding-top: 22px;
    @include fz(14);

    @include sp{
      padding-top: 10px;
    }

    .c_table_simple-link{
      @include a_tag($color_4,$color_4);
      font-weight: bold;

      &:link,&:visited,&:active{
        text-decoration: underline;
      }
      &:hover{
        text-decoration: none;
      }

    }

  }
  &-row:last-of-type &-head,
  &-row:last-of-type &-data{
    border-bottom: none;
  }

}


/* =================================
 forms
================================= */
// input
.c_input_1 {
}

.c_input_2 {
}

.c_input_3 {
}

// select
.c_select_1 {
}

.c_select_2 {
}

.c_select_3 {
}

// textarea
.c_textarea_1 {
}

.c_textarea_2 {
}

.c_textarea_3 {
}

// radio
.c_radio_1 {
}

.c_radio_2 {
}

.c_radio_3 {
}

// checkbox
.c_checkbox_1 {
}

.c_checkbox_2 {
}

.c_checkbox_3 {
}

// formset
.c_formset_1 {
  .c_input_hoge {
  }
  .c_select_hoge {
  }
  .c_textarea_hoge {
  }
}

/* =================================
 list
================================= */
// c_list_note
.c_list_note {
  @include fz(12);
  line-height: 2;

  >&-item{
    padding: 0 0 0 1em;
    text-indent: -1em;

    &:before{
      content: "※";
      text-indent: 0;
    }

  }

  &-num{
    counter-reset: listnum 0;

    .c_list_note-item{
      counter-increment: listnum 1;
      position: relative;
      padding: 0 0 0 2em;
      text-indent: 0;

      &:before{
        position: absolute;
        left: 0;
        top: 0;
        content: "※"counter(listnum);
      }

    }

  }

}

// c_list_square
.c_list_square {

  >&-item{
    padding: 0 0 0 1.5em;
    text-indent: -1.5em;

    &:before{
      content: "■";
      margin-right: 0.5em;
      text-indent: 0;
    }

  }

}

// c_list_dot
.c_list_dot {

  >&-item{
    padding: 0 0 0 1.5em;
    text-indent: -1.5em;

    &:before{
      content: "・";
      margin-right: 0.5em;
      text-indent: 0;
    }

  }

}

// c_list_circle
.c_list_circle {
  @include fz(14);

  >&-item{
    position: relative;
    padding: 0 0 0 11px;

    @include sp{
      padding-left: 10px;
    }

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: $color_4;

      @include sp{
        top: 5px;
        width: 5px;
        height: 5px;
      }

    }

  }

}

// c_list_num
.c_list_num {
  counter-reset: num 0;

  >&-item{
    counter-increment: num 1;
    position: relative;
    padding: 0 0 0 2em;
    list-style-type: none;
    line-height: 1.4;

    &:before{
      content: "0" counter(num);
      position: absolute;
      left: 0;
      top: 0;
      color: $color_4;
      font-family: $roboto;
    }

  }

}

// c_list_cnum
.c_list_cnum {
  counter-reset: cnum 0;

  >&-item{
    counter-increment: cnum 1;
    padding: 0 0 0 1.2em;
    text-indent: -1.2em;
    list-style-type: none;

    &:before{
      content: "(" counter(cnum) ")";
      text-indent: 0;
    }

  }

}

// c_list_onum
.c_list_onum {

  >&-item{
    padding: 0 0 0 1em;
    text-indent: -1em;
    list-style-type: none;

    &:before{
      text-indent: 0;
    }

    &:nth-of-type(1):before{content: "①";}
    &:nth-of-type(2):before{content: "②";}
    &:nth-of-type(3):before{content: "③";}
    &:nth-of-type(4):before{content: "④";}
    &:nth-of-type(5):before{content: "⑤";}
    &:nth-of-type(6):before{content: "⑥";}
    &:nth-of-type(7):before{content: "⑦";}
    &:nth-of-type(8):before{content: "⑧";}
    &:nth-of-type(9):before{content: "⑨";}
    &:nth-of-type(10):before{content: "⑩";}

    &_inner{
      text-indent: 0;
      line-height: 1.4;
    }

  }

}

/* =================================
 item
================================= */
// c_item_1
.c_item_1 {
}

// c_item_2
.c_item_2 {
}

// c_item_3
.c_item_3 {
}

/* =================================
 point
================================= */
.c_point {
  margin: 60px 0 0 0;

  @include sp{
    margin-top: 30px;
  }

}


/* =================================
 course
================================= */
.c_course {

  &_tag{

    &_item{
      display: inline-block;
      margin: 0 14px 10px 0;
      padding: 5px 10px 5px 15px;
      background: $color_29;
      border-radius: 20px;
      color: $color_21;
      line-height: 1;
      @include fz(14);
      font-weight: bold;

      &:before{
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 5px 0 0;
        border-radius: 2px;
        background: $color_21;
        vertical-align: 3px;
      }

    }

  }

  &_job{
    margin: 5px 0 0 0;
    padding: 15px;
    border-radius: 4px;
    background: $color_23;

    @include sp{
      margin: 0;
    }

    &_ttl{
      @include fz(16);
      font-weight: bold;

      &:before{
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background: $color_1;
        margin: 0 5px 0 0;
        border-radius: 2px;
        vertical-align: 3px;
      }

    }

    &_txt{
      margin: 5px 0 0 0;
      @include fz(14);
      line-height: 1.75;
    }

  }

}

/* =================================
 media
================================= */
.c_media{
  display: flex;
  justify-content: space-between;

  @include sp{
    display: block;
  }

  &_wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-2{

      .c_media{
        width: calc(50% - 20px);
        margin: 0 0 30px 0;

        @include sp{
          width: auto;
          margin: 0;
        }

      }

    }

  }

  &-imgr{
    flex-direction: row-reverse;
  }

  &_img{
    min-width: 48.5%;
    max-width: 48.5%;

    @include sp{
      min-width: initial;
      max-width: initial;

      &-hasttl{
        display: flex;
        align-items: center;

        .media_img{
          max-width: 39%;
          min-width: 39%;
          margin-right: 12px;
        }

      }

      &_ttl{
        line-height: 1.7;
      }

    }

  }

  &_txt{
    min-width: 48.5%;
    max-width: 48.5%;

    @include sp{
      min-width: initial;
      max-width: initial;
      margin: 15px 0 0 0;
    }

  }

  &_ttl{
    display: block;
    font-weight: bold;
    line-height: 1.7;
  }

  &_subitem {
    margin: 30px 0 0;
    display: flex;
    width: calc(50% - 20px);
  }
  &_subimg {
    width: 200px;
  }
  &_subtxt {
    width: calc(100% - 200px);
    padding: 0 0 0 15px;
    .c_media_subdesc {
      font-size: 14px;
      margin-top: 10px;
    }
  }
}

/* =================================
 collaboration
================================= */

.c_collaboration{

  &_here{
    position: relative;
    padding: 15px;
    border: 3px solid;
    @include fz(28);
    font-weight: bold;
    text-align: center;

    @include sp{
      padding: 12px;
      @include fz(24);
    }

    &:before,
    &:after{
      content: "";
      position: absolute;
      left: 50%;
      bottom: -43px;
      width: 50px;
      height: 6px;
      margin: -3px 0 0 -25px;
      background: $color_1;

      @include sp{
        bottom: -42px;
        width: 44px;
      }

    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }

  }

}

/* =================================
 card
================================= */
.c_card{

  &-merit,
  &-point,
  &-step{
    position: relative;
    counter-increment: merit 1;

    &:before{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 65px;
      height: 65px;
      padding: 7px 0 0 0;
      box-sizing: border-box;
      border-radius: 4px 0 4px 0;
      color: $color_21;
      font-family: $roboto;
      @include fz(12);
      font-weight: bold;
      text-align: center;
      line-height: 1;

      @include sp{
        width: 40px;
        height: 40px;
        padding-top: 5px;
        @include fz(10);
      }

    }

    &:after{
      content: "0" counter(merit);
      position: absolute;
      left: 0;
      top: 21px;
      z-index: 3;
      width: 65px;
      color: $color_21;
      font-family: $roboto;
      @include fz(39);
      font-weight: bold;
      text-align: center;
      line-height: 1;

      @include sp{
        top: 13px;
        width: 40px;
        @include fz(24);
      }

    }

  }
  &-merit{
    &:before {
      content: "MERIT";
    }
  }
  &-point{
    &:before {
      content: "POINT";
    }
  }
  &-step{
    &:before {
      content: "STEP";
    }
  }

  &_link{
    display: block;
  }

  &_img{

    &_wrap{
      overflow: hidden;
      border-radius: 4px;
    }

  }

  &_ttl{
    display: block;
    margin: 15px 0 0 0;
    font-weight: bold;

    @include sp{
      margin-top: 10px;
      line-height: 1.7;
    }

  }

  @include sp{

    &_head{
      display: flex;
      align-items: center;

      .c_card_img{
        min-width: 45%;
        max-width: 45%;
        margin-right: 20px;
      }

    }

  }

  &_txt{
    margin: 10px 0 0 0;
  }

  &_sub{
    display: block;
    margin: 10px 0 0 0;
    @include fz(12);
    font-weight: bold;

    @include sp{
      margin: 7px 0 0 0;
    }

    &+.c_card_ttl{
      margin: 5px 0 0 0;

      @include sp{
        margin: 0;
        @include fz(14);
      }

    }

  }

  .c_link-sp{
    line-height: 1.5;

    @include pc{

      &:before,
      &:after{
        display: none;
      }

    }

    @include sp{
      padding-right: 20px;

      &:before{
        top: 50%;
        margin-top: -10px;
      }

      &:after{
        top: 50%;
        margin-top: -4px;
      }

    }

  }

}

.c_card_img {
  &-guest {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    @include sp {
      height: 186px;
    }
  }
}

.c_card_wrap{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  counter-reset: merit 0;

  @include sp{
    display: block;
  }

  &-2{
    gap: 40px;

    .c_card{
      width: calc(50% - 20px);

      @include sp{
        width: auto;
      }

    }

  }

  &-3{
    justify-content: flex-start;
    gap: 25px;

    .c_card{
      width: calc(33.33% - 17px);

      @include sp{
        width: auto;
      }

    }

  }

  &-4{
    gap: 0 20px;

    &:before,
    &:after{
      content:"";
      display: block;
      width: calc(25% - 20px);
    }
    &:before{
      order:1;
    }

    .c_card{
      width: calc(25% - 20px);
      margin-bottom: 20px;

      @include sp{
        width: auto;
      }

      &-double{
        width: calc(50% - 15px);
      }

    }

  }

  @include sp{

    &-sp2{
      display: flex;
      gap: 0;

      .c_card{
        width: calc(50% - 7.5px);
        margin-bottom: 30px;

        &-double{
          width: 100%;
        }

      }

    }

    &-splr{
      display: block;

      .c_card{
        display: flex;
        align-items: center;
        width: auto;

        &+.c_card{
          margin: 30px 0 0 0;
        }

        .c_card_img{
          min-width: 50%;
          max-width: 50%;
        }

        .c_card_ttl{
          margin: 0 0 0 10px;
          line-height: 1.7;
        }

      }


    }

  }

}

.c_card_grid_wrap {
  display: grid;
  row-gap: 60px;
  counter-reset: merit_grid 0;
  @include sp {
    row-gap: 30px;
  }
}
.c_card_grid {
  position: relative;
  z-index: 0;
  padding: 0 0 0 85px;
  counter-increment: merit_grid 1;
  @include sp {
    padding: 0;
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 65px;
    height: 65px;
    padding: 7px 0 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px 0 4px 0;
    color: #fff;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    content: "MERIT";
    background: linear-gradient(to right, #fff0b1 0%, #f5836e 100%);
    @include sp {
      width: 39px;
      height: 39px;
      padding-top: 5px;
      font-size: 10px;
      font-size: 1rem;
    }
  }
  &:after {
    content: "0" counter(merit_grid);
    position: absolute;
    left: 0;
    top: 21px;
    z-index: 3;
    width: 65px;
    color: #fff;
    font-family: "Roboto Slab", serif;
    font-size: 39px;
    font-size: 3.9rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    @include sp {
      top: 13px;
      width: 39px;
      font-size: 24px;
      font-size: 2.4rem;
    }
  }
  &__ttl {
    @include fz(26);
    font-weight: bold;
    line-height: 1.36;
    margin: 15px 0 0;
    @include sp {
      @include fz(20);
      line-height: 1.6;
      margin: 3px 0 0;
      padding: 0 0 0 49px;
    }
  }
  &__txt {
    margin: 25px 0 0;
    @include sp {
      margin: 15px 0 0;
    }
  }
  &.__col2 {
    .c_card_grid {
      &__ttl {
        float: left;
        width: calc(50% - 20px);
        @include sp {
          float: none;
          width: 100%;
        }
      }
      &__img {
        float: right;
        width: calc(50% - 20px);
        margin: 0 0 0 40px;
        @include sp {
          float: none;
          width: 100%;
          margin: 15px 0 0;
        }
      }
      &__txt {
        float: left;
        width: calc(50% - 20px);
        @include sp {
          margin: 15px 0 0;
          width: 100%;
        }
      }
    }
  }
}

/* =================================
 photo
================================= */
.c_photo{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;

  @include sp{
    display: block;
  }

  &-img{
    width: calc(33.33% - 17px);

    @include sp{
      width: auto;
    }

  }

}

/* =================================
 message
================================= */
.c_message{
  position: relative;
  margin: 20px 0 0 200px;
  padding: 30px;
  border: 2px solid $color_29;
  border-radius: 4px;

  @include sp{
    margin: 30px 0 0 0;
    padding: 0;
    border: none;
  }

  &_wrap{
    position: relative;
    margin: 60px 0 0 0;
    max-height: 680px;
    overflow: hidden;

    @include sp{
      margin: 0;
      max-height: 1000px;
    }

    &:after{
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 265px;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.83) 46.8%, $color_21 100%);

      @include sp{
        height: 400px;
      }

    }

    &.is-show{
      max-height: initial;

      &:after{
        display: none;
      }

    }

  }

  @include sp{

    &_head{
      display: flex;
      align-items: center;
    }

    &_body{
      margin: 10px 0 0 0;
      padding: 15px;
      border: 2px solid $color_29;
      border-radius: 4px;
    }

  }

  &_img{
    position: absolute;
    left: -200px;
    top: 0;
    width: 180px;

    @include sp{
      position: static;
      max-width: 130px;
      min-width: 130px;
      margin-right: 10px;
    }

  }

  &_lead{
    display: block;
    margin: 0 0 10px;
    @include fz(20);
    font-weight: bold;

    @include sp{
      @include fz(18);
      line-height: 1.7;
    }

  }

  &_name{
    margin: 10px 0 0;
    font-weight: bold;
    text-align: right;
  }

  &_btn{
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;

    @include sp{
      left: 10px;
      width: calc(100% - 20px);
    }

  }

}

/* =================================
 iconcard
================================= */
.c_iconcard{
  position: relative;
  display: flex;
  align-items: center;
  width: calc(50% - 15px);
  margin: 0 0 14px 0;
  padding: 30px 30px 30px 20px;
  border-radius: 4px;
  background:$color_23;

  @include sp{
    width: auto;
    margin: 10px 0 0 0;
    padding: 20px 20px 30px;
  }

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 15%;
    height: 70%;
    border-left: 3px solid;
  }

  &_wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include sp{
      display: block;
    }

  }

  &_icon{
    position: relative;
    min-width: 123px;
    max-width: 123px;
    min-height: 123px;
    max-height: 123px;
    margin: 0 20px 0 0;
    border-radius: 64px;

    @include sp{
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      max-height: 80px;
      margin-right: 10px;
    }

    &_img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

  }

  &_ttl{
    display: block;
    @include fz(18);
    font-weight: bold;
    line-height: 1.4;

    @include sp{
      @include fz(14);
      line-height: 1.7;
    }

  }

  &_content{
    margin: 10px 0 0 0;
    @include fz(14);
    line-height: 2;

    @include sp{
      @include fz(13);
      line-height: 1.7;
    }

  }

}

.l_course_work{

  .c_iconcard{
    width: calc(50% - 20px);
    margin: 0;
    padding: 25px 25px 25px 40px;

    @include sp{
      width: auto;
      margin: 10px 0 0 0;
      padding: 25px 15px;

      &_txt{
        flex-grow: 2;
      }

    }

    &:before{
      display: none;
    }

    &_icon{
      min-width: 118px;
      max-width: 118px;
      min-height: 118px;
      max-height: 118px;
      margin: 0 30px 0 0;

      @include sp{
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
        margin: 0 20px 0 0;
      }

    }

    &_sub{
      display: block;
      @include fz(20);
      font-weight: bold;
      line-height: 1;

      @include sp{
        @include fz(18);
      }

    }

    &_ttll{
      margin: 10px 0 0 0;
      @include fz(75);
      font-weight: bold;
      line-height: 1;

      @include sp{
        @include fz(60);
      }

      &_m{
        @include fz(60);

        @include sp{
          @include fz(45);
        }

      }

      &_s{
        @include fz(36);

        @include sp{
          @include fz(26);
        }

      }

      &_v{
        display: inline-block;
        width: 1em;
        margin: 0 5px 0 0;
        @include fz(23);
        line-height: 1.3;

        @include sp{
          @include fz(18);
        }

      }

      &_xs{
        @include fz(23);

        @include sp{
          @include fz(16);
        }

      }

    }

  }

}

/* =================================
 news
================================= */
.c_news{

  &_link{
    display: block;
    transition: .4s ease;

    &:hover{

      @include pc{
        opacity: .5;
      }

    }

  }

  &_img{
    border-radius: 4px;
    overflow: hidden;
  }

  &_cat{
    display: inline-block;
    margin: 10px 0 0 0;
    width: 136px;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
    @include fz(10);
    color: $color_21;
  }

  &_head{
    display: flex;
    margin: 10px 0 0 0;
  }
  &_tag{
    width: 125px;
    margin: 0 15px 0 0;
    border: 1px solid $color_2;
    border-radius: 100px;
    text-align: center;
    @include fz(10);
    color: $color_30;
    line-height: 22px;
  }
  &_space{

    @include sp{
      width: 100%;
    }

  }
  &_date{
    line-height: 24px;
    @include fz(12);
    font-weight: bold;
    font-family: $roboto;
    color: $color_2;
  }
  &_mod{
    line-height: 24px;
    @include fz(14);
    color: $color_1;

    &:before{
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0 3px 0 0;
      background: url(#{$img_dir}/course/news/mod.svg) no-repeat;
      background-size: contain;
      vertical-align: -2px;
    }

  }

  &_txt{
    margin: 10px 0 0 0;
    @include fz(14);
    line-height: 1.4;
  }

}

.c_news_wrap{
  display: grid;

  @include sp{
    position: relative;
    display: block;
    margin-left: 25px;
    margin-right: 25px;

    .slick-prev,
    .slick-next{
      z-index: 5;
      top: 37%;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: $color_1;

      &:before{
        position: absolute;
        left: 50%;
        top: 50%;
        @include triangle(fff);
        opacity: 1;
        width: 9px;
        height: 8px;
        margin: -5px 0 0 -4px;
        font-size: 0;
      }

    }
    .slick-prev{
      left: -25px;
      transform: translate(0,-50%) scaleX(-1);
    }
    .slick-next{
      right: -25px;
    }

    .slick-disabled{
      background: $color_3;

      &:before{
        @include triangle(222);
      }

    }

  }

  &-3{
    grid-template-columns: repeat(3,1fr);
    gap: 24px;

    .c_news{

      @include sp{
        width: auto;
      }

    }

  }

}

//slick
@include sp{

  .slick-dots{
    bottom: -34px;

    li{
      width: 12px;
      height: 12px;
      margin: 0 5px;
      border-radius: 6px;
      overflow: hidden;
      background: $color_3;

      button{
        width: 100%;
        height: 100%;

        &:before{
          display: none;
        }

      }

    }

  }

}

/* =================================
 timetable
================================= */
.c_timetable{
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 4px;
  font-weight: bold;

  @include sp{
    width: 180%;
    table-layout: fixed;
  }

  &_wrap{

    @include sp{
      position: relative;

      &:before{
        content: "";
        position: absolute;
        z-index: 2;
        left: -20px;
        top: 0;
        width: 117px;
        height: 100%;
        background: $color_23;
      }

      &:after{
        content: "";
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        z-index: 2;
        width: 233px;
        height: 151px;
        margin: -75px 0 0 -117px;
        background: url(#{$img_dir}/common/scrollx.png) no-repeat;
        background-size: contain;
        pointer-events: none;
        transition: .2s ease;
      }

      &.scrolled:after{
        opacity: 0;
      }

    }

  }

  &_scroll{

    @include sp{
      overflow-x: auto;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 7px;
    }

  }

  &-head,
  &-data{
    text-align: center;
    vertical-align: middle;
    border: 4px solid #f5f5f5;
  }

  &-header{

    .c_timetable-head{
      padding: 10px;
      color: $color_21;
      @include fz(18);

      @include sp{
        padding: 5px;
        @include fz(14);
      }

      &:empty{
        background: $color_23;
      }

    }

  }

  &-body{

    @include sp{
      width: 100%;
    }

    .c_timetable-head{
      padding: 20px;
      background: $color_21;
      @include fz(18);

      @include sp{
        position: sticky;
        left: 0;
        z-index: 2;
        padding: 15px 10px;
        @include fz(13);
      }

    }

    .c_timetable-data{
      @include fz(14);
      line-height: 1.4;

      @include sp{
        padding: 5px 10px;
        @include fz(13);
        white-space: nowrap;
      }

      &:empty{
        background: $color_21;
      }

    }

  }

}

/* =================================
 voice
================================= */
.c_voice{
  display: flex;
  justify-content: space-between;

  @include sp{
    display: block;
  }

  &_img{
    position: relative;
    min-width: 29.6%;
    max-width: 29.6%;
    padding: 0 6% 0 0;
    box-sizing: content-box;
    font-weight: bold;
    line-height: 1;

    @include sp{
      display: flex;
      align-items: center;
      min-width: initial;
      max-width: initial;
      padding: 0;
      line-height: 1.7;
    }

    &:after{
      content: "";
      position: absolute;
      z-index: 2;
      right: -2px;
      bottom: 0;
      width: 2px;
      height: 26px;
      background: $color_21;

      @include sp{
        display: none;
      }

    }

    &_image{
      margin: 0 0 10px 0;

      @include sp{
        max-width: 150px;
        min-width: 150px;
        margin: 0 20px 0 0;
      }

    }

  }

  &_txt{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 20px;
    border-left: 2px solid;

    @include sp{
      margin: 26px 0 0 0;
      padding: 10px 0 0;
      border-left: none;
      border-top: 2px solid;
    }

    &:before,
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -14px;
      border-right: 20px solid;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;

      @include sp{
        transform: rotate(90deg);
        left: 50% !important;
        margin-top: 0;
        margin-left: -14px;
      }

    }

    &-at{
      justify-content: flex-start;

      &:before,
      &:after{
        margin-top: -27px;

        @include sp{
          margin-top: 0;
        }

      }

    }

    &:before{
      left: -20px;

      @include sp{
        top: -24px;
      }

    }
    &:after{
      left: -17px;
      border-right-color: $color_21;

      @include sp{
        top: -21px;
      }

    }

  }

  &_btm{
    font-weight: bold;
    @include fz(18);

    @include sp{
      margin: 0;
      @include fz(14);
    }

  }

  &_lead{
    position: relative;
    @include fz(18);
    font-weight: bold;
    line-height: 1.7;

    @include sp{
      @include fz(16);
    }

    &:before,
    &:after{
      position: absolute;
      width: 37px;
      height: 31px;
    }
    &:before{
      left: 0;
      top: 0;

      @include sp{
        left: -10px;
      }

    }
    &:after{
      right: 0;
      bottom: -10px;
      transform: rotate(180deg);

      @include sp{
        right: -10px;
      }

    }

    &_inner{
      position: relative;
      z-index: 2;
      display: block;
      padding: 16px 0 0 0;

      @include sp{
        padding: 10px 0 0 0;
      }

    }

  }

  &-v{

    .c_voice_img{

      @include sp{
        align-items: stretch;
      }

      &_image{
        margin: 0;

        @include sp{
          max-width: 30%;
          min-width: 30%;
        }

      }

      &:after{
        display: none;
      }

      &_bubble{
        display: none;

        @include sp{
          position: relative;
          display: block;
          margin: 0 0 0 18px;
          padding: 0 0 0 10px;
          border-left: 2px solid;
          @include fz(18);
          width: calc(70% - 18px);

          &:before,
          &:after{
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -5px;
            border-right: 10px solid;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
          }
          &:before{
            left: -10px;
          }
          &:after{
            left: -7px;
            border-right-color: $color_21;
          }

          &_inner{
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            text-align: center;

            &:before{
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 17px;
            }

            &:after{
              position: absolute;
              right: 0;
              bottom: 0;
              width: 20px;
              height: 17px;
              transform: rotate(180deg);
            }

          }

        }

      }

    }

    .c_voice_txt{

      @include sp{
        margin: 20px 0 0 0;
        padding: 0;
        border: none;
        @include fz(14);

        &:before,
        &:after{
          display: none;
        }

      }

    }

  }

  &-s{

    .c_voice_name{
      display: flex;
      align-items: flex-start;

      @include sp{
        display: none;
      }

      &_image{
        max-width: initial;
        min-width: initial;
        height: auto;
        margin-left: 20px;
      }

    }

    .c_voice_img{
      min-width: 23%;
      max-width: 23%;
      padding: 0 6% 0 0;

      @include sp{
        min-width: initial;
        max-width: initial;

        .c_voice_img_info .c_voice_img_image{
          min-width: initial;
          max-width: initial;
          margin: 10px 0 0 0;
        }

      }

      &_image{
        margin: 0;

        @include sp{
          margin: 0 20px 0 0;
        }

      }

      &:after{
        display: none;
      }

      &_info{

        @include sp{
          display: flex;
        }

      }

    }

    .c_voice_txt{
      border-left-color: $color_3;

      @include sp{
        border-top-color: $color_3;
      }

      &:before{
        border-right-color: $color_3;
      }

    }

    .c_voice_lead{

      &_inner{
        padding: 0;
      }

      &:before,&:after{
        display: none;
      }

    }

  }

  &-imgr{
    flex-direction: row-reverse;

    .c_voice_img{
      padding: 0 0 0 6%;

      @include sp{
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0;

        &_image{
          margin: 0 0 0 20px;
        }

      }

      &:after{
        right: auto;
        left: -2px;
      }

    }

    .c_voice_txt{
      padding: 0 20px 0 0;
      border-left: none;
      border-right: 2px solid;

      @include sp{
        padding: 10px 10px 0;
        border-right: none;
      }

      &:before,
      &:after{
        border-right: none;
        border-left: 20px solid;

        @include sp{
          transform: rotate(-90deg);
          left: 50% !important;
        }

      }
      &:before{
        left: auto;
        right: -20px;

        @include sp{
          right: auto;
        }

      }
      &:after{
        left: auto;
        right: -17px;
        border-left-color: $color_21;

        @include sp{
          right: auto;
        }

      }

    }

    &.c_voice-v{

      @include sp{

        .c_voice_img_image{
          margin: 0;
        }

        .c_voice_img_bubble{
          margin: 0 18px 0 0;
          padding: 0 10px 0 0;
          border-left: none;
          border-right: 2px solid;
          width: calc(70% - 18px);

          &:before,
          &:after{
            border-right: none;
            border-left: 10px solid;
          }

          &:before{
            left: auto;
            right: -10px;
          }
          &:after{
            left: auto;
            right: -7px;
            border-left-color: $color_21;
          }

        }

      }

    }

  }

}

/* =================================
 c_step
================================= */
.c_step{
  position: relative;
  counter-reset: step 0;

  &:before{
    content: "";
    position: absolute;
    left: 31px;
    height: 100%;
    border-left: 2px solid $color_3;

    @include sp{
      left: 19px;
    }

  }

  &_item{
    position: relative;
    overflow: hidden;
    counter-increment: step 1;
    padding: 0 0 0 85px;

    @include sp{
      padding-left: 50px;
      overflow: initial;
    }

    &+&{
      margin: 60px 0 0 0;

      @include sp{
        margin-top: 30px;
      }

    }

    &:last-of-type{
      background: $color_21;
    }

    &:before{
      content: "STEP";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 65px;
      height: 65px;
      padding: 7px 0 0 0;
      box-sizing: border-box;
      border-radius: 4px;
      color: $color_21;
      font-family: $roboto;
      @include fz(12);
      font-weight: bold;
      text-align: center;
      line-height: 1;

      @include sp{
        width: 40px;
        height: 40px;
        padding-top: 5px;
        @include fz(10);
      }

    }

    &:after{
      content: "0" counter(step);
      position: absolute;
      left: 0;
      top: 21px;
      z-index: 3;
      width: 65px;
      color: $color_21;
      font-family: $roboto;
      @include fz(39);
      font-weight: bold;
      text-align: center;
      line-height: 1;

      @include sp{
        top: 13px;
        width: 40px;
        @include fz(24);
      }

    }

  }

  &_img{
    float: right;
    width: calc(50% - 20px);
    margin: 0 0 0 40px;

    @include sp{
      float: none;
      width: 100%;
      margin: 15px 0 0 0;
    }

  }

  &_head+.c_step_txt{
    float: left;
    width: calc(50% - 20px);

    @include sp{
      float: none;
      width: auto;
    }

  }

  &_ttl{
    float: left;
    width: calc(50% - 20px);
    padding: 15px 0 0 0;
    @include fz(26);
    font-weight: bold;
    line-height: 1.3;
    .small {
      font-size: .77em;
    }
    @include sp{
      float: none;
      width: auto;
      min-height: 40px;
      padding: 8px 0 0 0;
      @include fz(20);
    }

  }

  &-noimg .c_step_ttl{
    float: none;
    width: auto;
  }

  &-short{

    &:before{
      left: 44px;

      @include sp{
        left: 23px;
      }

    }

    .c_step_item{
      padding: 0 0 0 100px;

      @include sp{
        padding-left: 47px;
      }

      &+.c_step_item{
        margin: 40px 0 0 0;

        @include sp{
          margin-top: 30px;
        }

      }

      &:before{
        width: 90px;
        height: 37px;
        padding: 12px 40px 0 8px;
        text-align: left;
        line-height: 1;
        @include fz(16);

        @include sp{
          width: 80px;
          height: 33px;
          padding: 10px 30px 0 7px;
          @include fz(15);
        }

      }

      &:after{
        left: 54px;
        top: 5px;
        width: auto;
        @include fz(25);
        text-align: left;
        color: $color_1;

        @include sp{
          left: 48px;
          @include fz(22);
        }

      }

    }

    .c_step_ttl{
      float: none;
      width: auto;
      padding: 3px 0 0 0;
      @include fz(22);
      line-height: 1.45;

      @include sp{
        min-height: initial;
        padding: 0 0 0 40px;
        @include fz(18);
        line-height: 33px;
      }

    }

    .c_step_txt{
      width: auto;
      margin-top: 10px;
    }

  }

  &_txt{
    margin: 15px 0 0 0;

    @include sp{
      margin-top: 10px;
    }

    .c_btn{
      width: 260px;
      padding: 19px 20px;
      @include fz(16);
      color: $color_21;
      text-align: center;

      @include sp{
        display: block;
        width: auto;
        padding: 15px;
      }

      &-pamphlet{
        background: linear-gradient(to right, $color_31 0%, $color_32 100%);
      }
      &-opencampus{
        background: linear-gradient(to right, $color_33 0%, $color_17 100%);
      }

      &:before{
        padding-top: 19px;
        padding-bottom: 19px;
      }

      &:after{
        background: $color_21;
      }

      &:hover{

        @include pc{
          background: $color_25;
        }

      }

    }

  }

  &_inner{
    clear: both;
  }

}

/* =================================
 faq
================================= */
.c_faq{
  @include fz(14);
  line-height: 1.4;
  counter-reset: faq 0;
  border-bottom: 1px solid $color_3;

  &_q{
    position: relative;
    border-top: 1px solid $color_3;
    padding: 15px 0;
    line-height: 1.75;
    font-weight: bold;
    counter-increment: faq 1;
    cursor: pointer;

    &:first-of-type{
      border: none;
    }

    &:before,
    &:after{
      position: absolute;
      right: 10px;
      top: 50%;
      content: "";
      width: 26px;
      margin-top: -1px;
      border-top: 2px solid $color_1;

      @include sp{
        right: 0;
        width: 16px;
        top: 28px;
      }

    }
    &:after{
      transform: rotate(90deg);
      transition: .4s ease;
    }

    &.is-open:after{
      transform: rotate(0);
      opacity: 0;
    }

    &-inner{
      display: flex;
      align-items: center;
      padding-right: 50px;

      @include sp{
        align-items: flex-start;
        padding-right: 30px;
      }

      &:before{
        content: "Q"counter(faq)".";
        flex-shrink: 0;
        width: 65px;
        padding-right: 20px;
        @include fz(24);
        font-weight: bold;
        text-align: right;

        @include sp{
          width: auto;
          padding-right: 15px;
          text-align: left;
          white-space: nowrap;
          line-height: 1;
        }

      }

    }

  }

  &_a{
    display: flex;
    padding: 0 0 15px 0;

    &:before{
      content: "A.";
      flex-shrink: 0;
      width: 60px;
      padding-right: 25px;
      @include fz(18);
      font-weight: bold;
      color: $color_3;
      text-align: right;

      @include sp{
        width: 50px;
        padding-right: 15px;
        text-align: left;
      }

    }

    .link_txt {
      @include a_tag($color_37,$color_37);

      &:link,&:visited,&:active{
        text-decoration: underline;
      }
      &:hover{
        @include pc {
          text-decoration: none;
        }
      }
    }

  }

  &-large{
    border: none;

    .c_faq_q{
      margin: 15px 0 0 0;
      padding: 10px 15px;
      border-radius: 4px;
      background: $color_23;
      border: none;
      @include fz(18);

      @include sp{
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 35px;
        @include fz(16);
      }

      &:before{
        content: "";
        position: absolute;
        right: 15px;
        top: 20px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background: $color_1;

        @include sp{
          right: 10px;
          top: 18px;
          width: 25px;
          height: 25px;
        }

      }

      &:after{
        content: "";
        position: absolute;
        right: 22.5px;
        top: 31px;
        @include triangle(fff);
        width: 10px;
        height: 10px;
        transition: .4s ease;

        @include sp{
          right: 17.5px;
          top: 26px;
          width: 9px;
          height: 9px;
          border: none;
          margin: 0;
        }

      }

      &-inner{
        position: relative;
        display: block;
        padding: 6px 0 0 60px;
        min-height: 45px;

        @include sp{
          min-height: 38px;
          padding-top: 5px;
          padding-left: 53px;
        }

        &:before{
          content: "Q";
          position: absolute;
          left: 0;
          top: 0;
          width: 45px;
          height: 45px;
          border-radius: 4px;
          background: linear-gradient(to right, $color_33 0%, $color_17 100%);
          line-height: 45px;
          padding: 0;
          @include fz(30);
          text-align: center;
          font-family: $roboto;
          color: #fff;

          @include sp{
            width: 38px;
            height: 38px;
            line-height: 38px;
            @include fz(24);
          }

        }

      }

      &.is-open{
        border-radius: 4px 4px 0 0;

        &:after{
          transform: rotate(90deg) scaleX(-1);
          opacity: 1;
        }

      }

    }

    .c_faq_a{
      display: block;
      margin: 0;
      padding: 0 15px 20px;
      border-radius: 0 0 4px 4px;
      background: $color_23;
      border: none;

      @include sp{
        padding: 0 10px 15px;
      }

      &:before{
        display: none;
      }

      &-inner{
        position: relative;
        padding: 10px 0 0 60px;
        border-top: 1px solid $color_3;
        @include fz(16);
        line-height: 1.75;

        @include sp{
          min-height: 38px;
          padding-left: 53px;
          @include fz(14);
        }

        &:before{
          content: "A";
          position: absolute;
          left: 0;
          top: 10px;
          border: 1px solid $color_17;
          width: 45px;
          height: 45px;
          line-height: 43px;
          padding: 0;
          border-radius: 4px;
          @include fz(30);
          text-align: center;
          color: $color_17;
          font-family: $roboto;
          font-weight: bold;

          @include sp{
            width: 38px;
            height: 38px;
            line-height: 38px;
            @include fz(24);
          }

        }

      }

    }

  }

}

/* =================================
 txt
================================= */
.c_txt{
  @include fz(16);
  line-height: 1.75;

  @include sp{
    @include fz(14);
  }

}

/* =================================
 lead
================================= */
.c_lead{
  display: block;
  @include fz(20);
  font-weight: bold;
  line-height: 1.7;

  @include sp{
    @include fz(18);
    line-height: 1.45;
  }

}

/* =================================
 c_anchor
================================= */
.c_anchor{
  @include a_tag($color_34,$color_34);
  font-weight: bold;

  &:after{
    content: "・";
  }

  &:link,&:link,&:link{
    text-decoration: underline;
  }

  &:hover{
    @include pc{
      text-decoration: none;
    }
  }

}

/* =================================
 box
================================= */
.c_box{
  padding: 15px;
  border-radius: 4px;

  &-bg1{
    background: $color_35;
  }
  &-bg2{
    background: $color_36;
  }

  &_lead{
    @include fz(16);
    font-weight: bold;

    @include sp{
      @include fz(14);
    }

    &:before{
      content: "・";
    }

  }

  &_txt{
    margin-top: 5px;
    @include fz(14);
    line-height: 1.7;

    @include sp{
      margin-top: 8px;
      @include fz(12);
      line-height: 1.8;
    }

  }

}

/* =================================
 blogcard
================================= */
.c_blogcard{
  margin: 40px 0 0 0;
  border-radius: 4px;
  border: 2px solid $color_2;

  &_link{
    display: flex;
    align-items: flex-start;
    padding: 30px;

    @include sp{
      display: block;
      padding: 20px;
    }

  }

  &_img{
    min-width: 250px;
    max-width: 250px;
    margin: 0 30px 0 0;

    @include sp{
      min-width: initial;
      max-width: initial;
      margin: 0;
    }

  }

  &_ttl{
    @include fz(20);
    font-weight: bold;
    line-height: 1.6;

    @include sp{
      margin-top: 15px;
    }

  }

  &_meta{
    display: flex;
    justify-content: flex-start;
    margin: 15px 0 0 0;

    @include sp{
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;
    }

    .c_news_cat{
      margin: 0 10px 0 0;

      @include sp{
        margin: 0;
      }

    }
    .c_news_tag{
      margin: 0 10px 0 0;

      @include sp{
        margin: 0;
      }

    }
    .c_news_date{
      margin: 0 10px 0 0;
      @include fz(14);
      font-weight: normal;
      color: $color_1;

      @include sp{
        margin: 0;
      }

    }

  }

}


/* =================================
 twocol_flex 2column Flexbox
================================= */
.c_twocol {
  &_flex {
    margin: 30px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp {
      margin: 20px 0 0;
    }
  }
  &_item {
    width: calc(50% - 15px);
    &:nth-child(n+3) {
      margin: 30px 0 0;
    }
    @include sp {
      width: 100%;
      &:not(:first-child) {
        margin: 30px 0 0;
      }
    }
  }
  &_txt {
    margin: 15px 0 0;
  }
  &_ttl {
    font-weight: bold;
    @include sp {
      font-size: 18px;
    }
  }
  &_desc {
    margin: 5px 0 0;
    font-size: 12px;
    @include sp {
      font-size: 14px;
    }
  }
}


/* =================================
 c_vertical_scroll
================================= */
.c_vertical_scroll {
  overflow: scroll;
  width: calc(100% + $sp_side_padding);
  position: relative;
  z-index: 0;
  &__ico {
    display: none;
    opacity: 0;
    transition: opacity .3s;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    @include sp {
      max-width: 233px;
      width: 100%;
      display: block;
      opacity: 1;
    }
    &.is-hidden {
      opacity: 0;
    }
  }
}



/* =================================
 c_dec_txt
================================= */
.c_dec_txt {
  position: relative;
  z-index: 0;
  display: table;
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;
  @include sp {
    width: 100%;
  }
  &:before,
  &:after {
    content: "";
    width: 5px;
    height: 69px;
    background: url(../img/common/dec_txt.svg) no-repeat center / contain;
    position: absolute;
    top: -10px;
    @include sp {
      bottom: 0;
      top: auto;
    }
  }
  &:before {
    left: -20px;
    transform: rotate(-30deg);
    @include sp {
      left: 0;
    }
  }
  &:after {
    right: -20px;
    transform: rotate(30deg);
    @include sp {
      right: 10px;
    }
  }
}










//

@use 'setting' as *;

/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc {
  .u_pc {
    display: block !important;
    &_i {
      display: inline !important;
    }
    &_ib {
      display: inline-block !important;
    }
    &_f {
      display: flex !important;
    }
    &_if {
      display: inline-flex !important;
    }
    &_t {
      display: table !important;
    }
    &_tc {
      display: table-cell !important;
    }
  }
  .u_sp {
    display: none !important;
    &_i,
    &_ib,
    &_f,
    &_if,
    &_t,
    &_tc {
      display: none !important;
    }
  }
}

@include sp {
  .u_pc {
    display: none !important;
    &_i,
    &_ib,
    &_f,
    &_if,
    &_t,
    &_tc {
      display: none !important;
    }
  }
  .u_sp {
    display: block !important;
    &_i {
      display: inline !important;
    }
    &_ib {
      display: inline-block !important;
    }
    &_f {
      display: flex !important;
    }
    &_if {
      display: inline-flex !important;
    }
    &_t {
      display: table !important;
    }
    &_tc {
      display: table-cell !important;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;
  @include pc_tab {
    padding: 0;
    margin: 0 auto;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
// .u_txt_1 {
//   font-size: 1em;
//   line-height: 1;
//   letter-spacing: .1em;
//   @include pc {
//   }
// }

// .u_txt_2 {
//   font-size: 1em;
//   line-height: 1;
//   letter-spacing: .1em;
//   @include pc {
//   }
// }

// .u_txt_3 {
//   font-size: 1em;
//   line-height: 1;
//   letter-spacing: .1em;
//   @include pc {
//   }
// }

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

.u_sp_fwn{
  @include sp{
    font-weight: normal !important;
  }
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_va_m {
  vertical-align: middle;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

.u_sp_tac{
  @include sp{
    text-align: center !important;
  }
}

.u_sp_tal{
  @include sp{
    text-align: left !important;
  }
}

/* =================================
 text color
================================= */
.u_grad_underline {
  padding: 0 0 5px;
  &.__orange {
    background: linear-gradient(to right, rgba(255,248,216,1) 0%, rgba(250,192,182,1) 100%);
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;
  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

/* =================================
 font
================================= */
.u_en {
  font-family: $roboto;
}

/* =================================
 space
================================= */
.u_space{
  width: 0.3em;
}

/* =================================
 u_countupitem
================================= */
.is-show .u_countupitem{
  opacity: 1;
  transform: translateY(0);
}

.u_countupitem{
  opacity: 0;
  transition: 1.2s ease;
  transition-timing-function: cubic-bezier(.165,.84,.44,1);
  transform: translateY(30%);

  @for $i from 1 through 100 {
    &:nth-of-type(#{$i}) {
      transition-delay: #{$i*.1}s;
    }
  }

}

/* =================================
 margin
================================= */
@for $value from -100 through 100 {
  .u_m {
    &#{$value} {
      margin: #{$value}px;
    }
    &b#{$value} {
      margin-bottom: #{$value}px;
    }
    &t#{$value} {
      margin-top: #{$value}px;
    }
    &l#{$value} {
      margin-left: #{$value}px;
    }
    &r#{$value} {
      margin-right: #{$value}px;
    }
  }
  @include sp{
    .u_sp_m {
      &#{$value} {
        margin: #{$value}px !important;
      }
      &b#{$value} {
        margin-bottom: #{$value}px !important;
      }
      &t#{$value} {
        margin-top: #{$value}px !important;
      }
      &l#{$value} {
        margin-left: #{$value}px !important;
      }
      &r#{$value} {
        margin-right: #{$value}px !important;
      }
    }
  }
}

/* =================================
 width
================================= */
@for $value from 0 through 500 {
  .u_w {
    &#{$value} {
      width: #{$value}px;
    }
  }
  @include sp{
    .u_sp_w {
      &#{$value} {
        width: #{$value}px !important;
      }
    }
  }
}

/* =================================
 font-size
================================= */
@for $value from 10 through 50 {
  .u_fz {
    &#{$value} {
      font-size: $value + px;
      font-size: calc($value / 10) * 1rem;
    }
  }
  @include sp{
    .u_sp_fz {
      &#{$value} {
        font-size: $value + px !important;
        font-size: calc($value / 10) * 1rem !important;
      }
    }
  }
}

/* =================================
 line-height
================================= */
@for $value from 100 through 200 {
  .u_lh {
    &#{$value} {
      line-height: calc($value / 100);
    }
  }
  @include sp{
    .u_sp_lh {
      &#{$value} {
        line-height: calc($value / 100) !important;
      }
    }
  }
}

/* =================================
 display
================================= */
.u_db{
  display: block;
}
.u_dib{
  display: inline-block;
}
.u_di{
  display: inline;
}

@include sp{
  .u_sp_db{
    display: block !important;
  }
  .u_sp_dib{
    display: inline-block !important;
  }
  .u_sp_di{
    display: inline !important;
  }
}

/* =================================
 separetor
================================= */
.u_separetor{
  margin-top: 60px;
  padding-top: 60px;
  border-top: 3px solid $color_3;

  @include sp{
    margin-top: 45px;
    padding-top: 40px;
  }

}

/* =================================
 hidden
================================= */
.is_hidden{
  visibility: hidden;
}

/* =================================
 note
================================= */
.u_note{
  text-align: right;

  @include sp{
    padding: 0 0 0 1em;
    text-indent: -1em;
    text-align: left;
  }

  &:before{
    content: "※";
    text-indent: 0;
  }

}

/* =================================
 link
================================= */
.u_link{
  font-weight: bold;
  @include a_tag($color_37,$color_37);

  &:link,&:visited,&:active{
    text-decoration: underline;
  }
  &:hover{
    text-decoration: none;
  }

  &:before{
    content: "・";
  }

}
